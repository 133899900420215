import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import "./MyStats.css";

const MyStats = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [playerid, setPlayerid] = useState(null);
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwtDecode(access_token);
      if (decodedToken.sub) {
        setIsLogged(true);
        setPlayerid(decodedToken.sub);
        navigate(`/players/${decodedToken.sub}`);
      } else {
        setIsLogged(false);
      }
    } else {
      setIsLogged(false);
    }
  }, [access_token, navigate]);

  return (
    <>
      {!isLogged && (
        <>
          <h1 className="mt-5">My Stats</h1>
          <div className="container-sm stats-container">
            <p className="page-info">
              You must be signed in to view your stats.
            </p>
            <a
              className="btn btn-outline-primary sign-in-button"
              href="/login"
              role="button"
            >
              Sign In
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default MyStats;
