import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./RegistrationForm.css";

const RegistrationForm = (props) => {
  const INITIAL_STATE = {
    email: "",
    password: "",
    token: "",
  };

  const [formData, setFormData] = useState(INITIAL_STATE);
  const [notification, setNotification] = useState("");
  const [tokenMsg, setTokenMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const getUserInfo = async () => {
    try {
      const user_token = formData.token;
      const apiURL = "https://api.start.gg/gql/alpha";
      const headers = {
        Authorization: `Bearer ${user_token}`,
        "Content-Type": "application/json",
      };
      const query = `query  {
                        currentUser {
                            id
                            bio
                            birthday
                            genderPronoun
                            images {
                              url
                            }
                            location {
                              country
                              state
                              city
                            }
                            name
                            player {
                              id
                              gamerTag
                              prefix
                            }
                        }
            }`;
      const queryRes = await axios.post(
        apiURL,
        {
          query: query,
        },
        {
          headers,
        }
      );
      const data = queryRes.data;
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user_query = await getUserInfo();
      const user_id = user_query["data"]["currentUser"]["id"];
      const gamertag = user_query["data"]["currentUser"]["player"]["gamerTag"];
      const prefix = user_query["data"]["currentUser"]["player"]["prefix"];
      const image = user_query["data"]["currentUser"]["images"][0]["url"];
      const country = user_query["data"]["currentUser"]["location"]["country"];
      const state = user_query["data"]["currentUser"]["location"]["state"];
      const city = user_query["data"]["currentUser"]["location"]["city"];
      const name = user_query["data"]["currentUser"]["name"];
      const resp = await axios.post(
        "https://bracket-buddy.onrender.com/register",
        {
          id: user_id,
          password: formData.password,
          token: formData.token,
          gamertag: gamertag,
          prefix: prefix,
          image: image,
          country: country,
          state: state,
          city: city,
          name: name,
          email: formData.email,
        }
      );

      if (resp.status === 200) {
        setNotification(
          `Thank you for registering. A verification email has been sent to ${formData.email}. Please check your inbox.`
        );
        localStorage.setItem("access_token", resp.data.access_token);
        setFormData(INITIAL_STATE);
      } else if (resp.status === 400) {
        setTokenMsg(resp.data.message);
      } else {
        throw new Error("An unexpected error occurred.");
      }
    } catch (e) {
      console.error(e);
      setTokenMsg(
        "Something went wrong. Please verify your token or try again later."
      );
    }
  };

  return (
    <div className="container">
      {notification ? (
        <div className="alert alert-success col-sm-9 verify-notif" role="alert">
          {notification}
        </div>
      ) : null}
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <label htmlFor="email" className="col-form-label">
              Email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              required
            />
            <label htmlFor="password" className="col-form-label">
              Password
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="form-control"
              required
            />
            <label htmlFor="token" className="col-form-label">
              Token
              <span className="question-circle-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="question-circle"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ marginLeft: "5px", cursor: "pointer" }}
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                </svg>
                <div className="tooltip-message">
                  Enter the token provided to you by the start.gg developer
                  settings.
                </div>
              </span>
            </label>
            <input
              id="token"
              type="password"
              name="token"
              value={formData.token}
              onChange={handleChange}
              className="form-control"
              required
            />
            {tokenMsg ? (
              <div
                className="alert alert-danger col-sm-9 verify-notif"
                role="alert"
              >
                Please enter a valid token.
              </div>
            ) : null}
            <button type="submit" className="btn btn-outline-primary mt-2">
              Register
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
