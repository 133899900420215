import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./PlayerProfile.css";
import SocialIcon from "./SocialIcon";
import PlayerSummary from "./PlayerSummary";

const PlayerProfile = () => {
  const { playerid } = useParams();
  const [playerInfo, setPlayerInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [playerCountry, setPlayerCountry] = useState(null);
  const [pageInfo, setPageInfo] = useState(null);
  const [tourneyInfo, setTourneyInfo] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [activeTab, setActiveTab] = useState("stats");
  const [latestTourney, setLatestTourney] = useState(null);
  const [tourneyOutcome, setTourneyOutcome] = useState();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const changeTourneyPage = (newPage) => {
    setPageNum(newPage);
  };

  const makeBatchRequest = async (allQueries) => {
    try {
      const apiURL = "https://api.start.gg/gql/alpha";
      const apiKey = process.env.REACT_APP_START_GG_KEY;
      const headers = {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      };

      const res = await axios.post(apiURL, allQueries, { headers });
      return res.data;
    } catch (e) {
      console.error("Batch Query Error:", e);
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (playerid) {
          setLoading(true);
          const apiURL = "https://api.start.gg/gql/alpha";
          const apiKey = process.env.REACT_APP_START_GG_KEY;
          const headers = {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          };
          const playerQuery = `
                    query UserWithEvents($userId: ID!, $page: Int!, $perPage: Int!, $eventFilter: UserEventsPaginationFilter, $sortBy: String) {
                        user(id: $userId) {
                          id
                          authorizations {
                            type
                            externalUsername
                            url
                          }
                          bio
                          birthday
                          genderPronoun
                          images {
                            url
                          }
                          location {
                            country
                            state
                            city
                          }
                          name
                          player {
                            gamerTag
                            prefix
                          }
                          slug
                          events(query: { page: $page, perPage: $perPage, filter: $eventFilter, sortBy: $sortBy }) {
                             pageInfo {
                       total
                       totalPages
                       page
                       perPage
                             }
                            nodes {
                              type
                              id
                              competitionTier
                              name
                              startAt
                              numEntrants
                              rulesetId
                              slug
                              tournament {
                                id
                                name
                                images {
                                  url
                                }
                              }
                              userEntrant(userId: $userId) {
                                id
                                standing {
                                  placement
                                }
                                seeds {
                                  phase {
                                    phaseOrder
                                  }
                                  placement
                                  seedNum
                                }
                              }
                            }
                          }
                        }
                      }`;

          const queryRes = await axios.post(
            apiURL,
            {
              query: playerQuery,
              variables: {
                userId: playerid,
                page: pageNum,
                perPage: 5,
                eventFilter: {
                  videogameId: 1386, // SSBU videogameId, will not change
                  eventType: 1, // Singles only
                },
                sortBy: "startAt desc",
              },
            },
            {
              headers,
            }
          );
          setPlayerInfo(queryRes.data.data.user);
          setTourneyInfo(queryRes.data.data.user.events.nodes);
          setPageInfo(queryRes.data.data.user.events.pageInfo);

          if (
            queryRes.data.data.user.location &&
            queryRes.data.data.user.location.country
          ) {
            const country = queryRes.data.data.user.location.country;
            const res = await axios.post(
              "https://bracket-buddy.onrender.com/country_code",
              {
                country,
              }
            );
            setPlayerCountry(res.data.country_code);
          }

          const batchQueries = queryRes.data.data.user.events.nodes.map(
            (currEvent) => ({
              query: `
                        query UserEventSets($setFilters: SetFilters, $entrantId: ID!, $tourneyFilter: TournamentPageFilter, $eventFilter: EventFilter) {
                            tournaments(query: {filter: $tourneyFilter}) {
                              nodes {
                                id
                                       events(filter: $eventFilter) {
                                  id
                                  sets(sortType: RECENT, filters: $setFilters) {
                                      nodes {
                                        winnerId
                                        round
                                        games {
                                          selections {
                                            character {
                                              images {
                                                url
                                              }
                                            }
                                            entrant {
                                              id
                                              }
                                          }
                                        }
                                        displayScore(mainEntrantId: $entrantId)
                                        slots {
                                          entrant {
                                            id
                                            participants {
                                              prefix
                                              gamerTag
                                              user {
                                                id
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                }
                          }
                            }
                          }
                        `,
              variables: {
                eventFilter: { ids: [currEvent.id] },
                setFilters: {
                  entrantIds: [currEvent.userEntrant.id],
                  eventIds: [currEvent.id],
                },
                entrantId: currEvent.userEntrant.id,
                tourneyFilter: { ids: [currEvent.tournament.id] },
              },
            })
          );

          const tourneyOutcomeRes = await makeBatchRequest(batchQueries);
          setTourneyOutcome(tourneyOutcomeRes);
        }
      } catch (e) {
        return <div>Error retrieving info. Please try again later.</div>;
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, [playerid, pageNum]);

  useEffect(() => {
    if (tourneyInfo && tourneyInfo.length > 0 && pageInfo?.page === 1) {
      setLatestTourney(tourneyInfo[0]);
    }
  }, [tourneyInfo, pageInfo]);

  if (loading) {
    return (
      <div
        className="spinner-border text-primary"
        role="status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: "50vh",
          left: "50vw",
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (!playerInfo) {
    return <div>Error: Invalid player ID</div>;
  }

  const hasSocialMedia =
    playerInfo.authorizations &&
    Array.isArray(playerInfo.authorizations) &&
    playerInfo.authorizations.some((authorization) =>
      ["DISCORD", "TWITCH", "TWITTER"].includes(authorization.type)
    );

  return (
    <>
      <div className="mt-5 container player-profile-container">
        <div className="col-md-auto player-page-title">
          {playerInfo.images && playerInfo.images.length > 0 ? (
            <img
              src={playerInfo.images[0]["url"]}
              alt="Player"
              className="rounded-img-title"
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/SSB_logo.png"}
              alt="Player"
              className="rounded-img-title"
            />
          )}
        </div>
        <div className="player-page-info">
          <div className="player-page-info-tag">
            <span className="prefix">
              {playerInfo.player.prefix
                ? ` ${playerInfo.player.prefix} | `
                : ""}
            </span>
            {playerInfo.player.gamerTag}
          </div>
          <div className="player-page-info-name">
            {playerInfo.name ? `${playerInfo.name}` : ""}
          </div>
          {playerInfo.location && (
            <div className="player-page-info-location">
              {(playerInfo.location.city ||
                playerInfo.location.state ||
                playerInfo.location.country) && (
                <img
                  src={process.env.PUBLIC_URL + "/icons8-location-100.png"}
                  alt="Location"
                  className="player-page-info-location-icon"
                />
              )}
              {playerInfo.location.city ? `${playerInfo.location.city}` : ""}
              {playerInfo.location.city && playerInfo.location.state
                ? `, `
                : ""}
              {playerInfo.location.state ? `${playerInfo.location.state}` : ""}
              {(playerInfo.location.city || playerInfo.location.state) &&
              playerInfo.location.country
                ? `, `
                : ""}
              {playerInfo.location.country ? (
                <>
                  {`${playerInfo.location.country}`}
                  <span
                    className={`fi fi-${playerCountry.toLowerCase()} country-icon`}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          )}
          <div className="player-page-info-socials">
            {hasSocialMedia
              ? playerInfo.authorizations.map((each) => (
                  <div key={each.type} className="player-page-info-icon">
                    <SocialIcon
                      platform={each.type}
                      username={each.externalUsername}
                      url={each.url}
                    />
                  </div>
                ))
              : null}
          </div>
          <div className="player-page-summary">
            <PlayerSummary
              playerid={playerid}
              changeTourneyPage={changeTourneyPage}
              changeTab={handleTabChange}
              activeTab={activeTab}
              pageInfo={pageInfo}
              nodes={tourneyInfo}
              lastTourney={latestTourney}
              tourneyOutcome={tourneyOutcome}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerProfile;
