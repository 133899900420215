import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Home.css";

const Home = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const navigate = useNavigate();
  const hiddenComponentsRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 576;
      setIsMobile(mobile);

      if (!mobile) {
        hiddenComponentsRef.current.forEach((el) => {
          if (el) el.classList.remove("hidden");
        });
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    hiddenComponentsRef.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      hiddenComponentsRef.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const get_rankings = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `https://bracket-buddy.onrender.com/player-rankings?page=${1}`
        );
        setData(res.data.data);
      } catch (e) {
        return <div>Error retrieving player rankings.</div>;
      } finally {
        setLoading(false);
      }
    };
    get_rankings();
  }, []);

  const navigateToPlayerPage = (playerId) => {
    navigate(`/players/${playerId}`);
  };

  function formatNumbers(num) {
    return num ? num.toLocaleString() : "N/A";
  }

  return (
    <>
      <div className="banner-container">
        <img
          src={process.env.PUBLIC_URL + "/banner.svg"}
          alt="BracketBuddy"
          className="banner-image"
        />
        <div className="logo-container">
          <img
            src={process.env.PUBLIC_URL + "/bigbracket.svg"}
            alt="Bracket Left"
            className={`brand-logo-image-left ${isMounted ? "fade-in" : ""}`}
          />
          <div className="brand-name">
            <p className={`brand-name-first ${isMounted ? "fade-in" : ""}`}>
              Bracket
            </p>
            <p className={`brand-name-second ${isMounted ? "fade-in" : ""}`}>
              Buddy
            </p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "/bigbracket.svg"}
            alt="Bracket Right"
            className={`brand-logo-image-right ${isMounted ? "fade-in" : ""}`}
          />
        </div>
        <div className="banner-buttons">
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate(`/smash-arena`);
            }}
          >
            Smash Arena
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => {
              navigate(`/register`);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
      <div className="home-container">
        {isMobile ? (
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="jumbotron jumbotron-fluid">
                  <div className="container jumbotron-item-container">
                    <h1 className="display-4">Elevate Your SSBU Experience</h1>
                    <p className="lead">
                      Introducing <b>Bracket Buddy</b>, an application designed
                      for casual and competitive Super Smash Bros. Ultimate
                      players alike.
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="jumbotron jumbotron-fluid">
                  <div className="container jumbotron-item-container">
                    <h1 className="display-4">Fair Matches Made Simple</h1>
                    <p className="lead">
                      Organize matches from anywhere and mitigate the risk of
                      early counterpicks with the Smash Arena.
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="jumbotron jumbotron-fluid">
                  <div className="container jumbotron-item-container">
                    <h1 className="display-4">
                      Know Your Game and Competition
                    </h1>
                    <p className="lead">
                      Track your tournament performances and gain insight on
                      your character matchups.
                    </p>
                    <p className="footnote">
                      * Player data is pulled from{" "}
                      <a
                        href="https://www.start.gg/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        start.gg
                      </a>{" "}
                      accounts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        ) : (
          <div className="jumbotron-row">
            <div
              className="jumbotron jumbotron-fluid hidden"
              ref={(el) => hiddenComponentsRef.current.push(el)}
            >
              <div className="container jumbotron-item-container">
                <h1 className="display-4">Elevate Your SSBU Experience</h1>
                <p className="lead">
                  Introducing <b>Bracket Buddy</b>, an application designed for
                  casual and competitive Super Smash Bros. Ultimate players
                  alike.
                </p>
              </div>
            </div>
            <div
              className="jumbotron jumbotron-fluid hidden"
              ref={(el) => hiddenComponentsRef.current.push(el)}
            >
              <div className="container jumbotron-item-container">
                <h1 className="display-4">Fair Matches Made Simple</h1>
                <p className="lead">
                  Organize matches from anywhere and mitigate the risk of early
                  counterpicks with the Smash Arena.
                </p>
              </div>
            </div>
            <div
              className="jumbotron jumbotron-fluid hidden"
              ref={(el) => hiddenComponentsRef.current.push(el)}
            >
              <div className="container jumbotron-item-container">
                <h1 className="display-4">Know Your Game and Competition</h1>
                <p className="lead">
                  Track your tournament performances and gain insight on your
                  character matchups.
                </p>
                <p className="footnote">
                  * Player data is pulled from {"  "}
                  <a
                    href="https://www.start.gg/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    start.gg
                  </a>
                  {"  "}
                  accounts.
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="top-players-container mt-5">
          <div className="top-players-title">Top 25</div>
          <div className="card-deck">
            {data.map((each, index) => (
              <div
                className="card top-players-card"
                key={each.id}
                onClick={() => navigateToPlayerPage(each.id)}
              >
                <img
                  className="card-img-left"
                  src={
                    each.image
                      ? each.image
                      : process.env.PUBLIC_URL + "/BBL.svg"
                  }
                  alt="Player"
                />
                <div className="card-body top-player-card-body">
                  <h5 className="card-title top-player-name">
                    <span style={{ color: "#0067da" }}>
                      {each.prefix ? `${each.prefix} | ` : ""}
                    </span>
                    {each.gamertag}
                  </h5>
                  <div className="top-player-location">
                    {(each.city || each.state || each.country_name) && (
                      <img
                        src={
                          process.env.PUBLIC_URL + "/icons8-location-100.png"
                        }
                        alt="Location"
                        className="player-page-info-location-icon"
                      />
                    )}
                    {each.city ? `${each.city}` : ""}
                    {each.city && each.state ? `, ` : ""}
                    {each.state ? `${each.state}` : ""}
                    {(each.city || each.state) && each.country_name ? `, ` : ""}
                    {each.country_name ? (
                      <>
                        {`${each.country_name}`}
                        <span
                          className={`fi fi-${each.country_id.toLowerCase()} country-icon`}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="top-player-elo">Elo {each.elo_rating}</div>
                  <div className="top-player-wr">
                    {Math.round((each.set_wins / each.total_sets) * 1000) / 10}%
                    Win Rate
                  </div>
                  <div className="top-player-chars">
                    {" "}
                    {each.characters && each.characters.length > 0 ? (
                      <div
                        className="top-players-char-icon-container"
                        style={{ display: "flex" }}
                      >
                        {each.characters.slice(0, 3).map((char, i) => (
                          <img
                            key={i}
                            className="char-icon"
                            src={char.stock_icon}
                            alt="Character Icon"
                          />
                        ))}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
                <span className="badge bg-primary position-absolute top-0 start-0 top-player-badge">
                  {index + 1}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
