import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./PlayerRankings.css";

const PlayerRankings = () => {
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [maxButtons, setMaxButtons] = useState(5);

  useEffect(() => {
    const get_rankings = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `https://bracket-buddy.onrender.com/player-rankings?page=${currPage}`
        );
        setData(res.data.data);
        setTotalPages(res.data.total_pages);
      } catch (e) {
        return <div>Error retrieving player rankings.</div>;
      } finally {
        setLoading(false);
      }
    };
    get_rankings();
  }, [currPage]);

  useEffect(() => {
    const updateMaxButtons = () => {
      const width = window.innerWidth;
      setMaxButtons(width <= 360 ? 3 : 5);
    };

    updateMaxButtons();
    window.addEventListener("resize", updateMaxButtons);

    return () => {
      window.removeEventListener("resize", updateMaxButtons);
    };
  }, []);

  const getPageNumList = () => {
    const pageNumList = [];

    if (totalPages <= maxButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumList.push(i);
      }
    } else {
      const startPage = Math.max(1, currPage - Math.floor(maxButtons / 2));
      const endPage = Math.min(totalPages, startPage + maxButtons - 1);

      if (startPage > 1) {
        pageNumList.push(1, "...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumList.push(i);
      }

      if (endPage < totalPages) {
        pageNumList.push("...", totalPages);
      }
    }

    return pageNumList;
  };

  const navigateToPlayerPage = (playerId) => {
    navigate(`/players/${playerId}`);
  };

  function formatNumbers(num) {
    return num ? num.toLocaleString() : "N/A";
  }

  return (
    <>
      <h1 className="mt-5 leaderboards">Top 250</h1>
      <div className="leaderboards-container">
        <div className="player-table">
          <div className="table-scroll-container">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="rankings-header" scope="col">
                    #
                  </th>
                  <th className="rankings-header">Player</th>
                  <th className="rankings-header">Elo</th>
                  <th className="rankings-header">Win %</th>
                  <th className="rankings-header">Chars.</th>
                </tr>
              </thead>
              <tbody>
                {data.map((each, index) => (
                  <tr
                    key={each.id}
                    onClick={() => navigateToPlayerPage(each.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <th scope="row">
                      {formatNumbers(
                        index + 1 + each.per_page * (currPage - 1)
                      )}
                    </th>
                    <td className="table-row-info">
                      <span style={{ color: "#0067da" }}>
                        {each.prefix ? `${each.prefix} | ` : ""}
                      </span>
                      {each.gamertag}{" "}
                      {each.country_name && (
                        <span
                          className={`fi fi-${each.country_id.toLowerCase()}`}
                        />
                      )}
                    </td>
                    <td>{formatNumbers(each.elo_rating)}</td>
                    <td>
                      {Math.round((each.set_wins / each.total_sets) * 100)}%
                    </td>
                    <td>
                      {each.characters && each.characters.length > 0 ? (
                        <div
                          className="char-icon-container"
                          style={{ display: "flex" }}
                        >
                          {each.characters.slice(0, 3).map((char, i) => (
                            <img
                              key={i}
                              className="char-icon"
                              src={char.stock_icon}
                              alt="Character Icon"
                            />
                          ))}
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="page-buttons">
          <nav className="page-navigation-container">
            <ul className="pagination justify-content-center player-rankings-pages">
              <li
                className={`page-item pagination-num ${
                  currPage === 1 ? "disabled" : ""
                }`}
              >
                <Link
                  to={`/player-rankings?page=${currPage - 1}`}
                  className="page-link pagination-num"
                  onClick={() => setCurrPage(currPage - 1)}
                  disabled={currPage === 1}
                >
                  {"<"}
                </Link>
              </li>
              {getPageNumList().map((pageNum, index) => (
                <li
                  key={index}
                  className={`page-item pagination-num ${
                    pageNum === "..." ? "disabled" : ""
                  }`}
                >
                  {pageNum === "..." ? (
                    <span className="page-link pagination-num">...</span>
                  ) : (
                    <Link
                      to={`/player-rankings?page=${pageNum}`}
                      className={`page-link pagination-num ${
                        pageNum === currPage ? "active" : ""
                      }`}
                      onClick={() => setCurrPage(pageNum)}
                    >
                      {formatNumbers(pageNum)}
                    </Link>
                  )}
                </li>
              ))}
              <li
                className={`page-item pagination-num ${
                  currPage === totalPages ? "disabled" : ""
                }`}
              >
                <Link
                  to={`/player-rankings?page=${currPage + 1}`}
                  className="page-link pagination-num"
                  onClick={() => setCurrPage(currPage + 1)}
                  disabled={currPage === totalPages}
                >
                  {">"}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <p className="footnote">
          * Rankings are determined by the <b>elo rating system</b>, a
          streamlined method for calculating relative skill levels of players in
          two-player games such as Chess and SSBU.
        </p>
      </div>
      {loading && (
        <div
          className="spinner-border text-primary"
          role="status"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50vh",
            left: "50vw",
          }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default PlayerRankings;
