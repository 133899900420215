import { React, useState, useRef } from "react";
import "./SelectionAssistant.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAccessToken from "./hooks/useAccessToken";

const SelectionAssistant = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [oppGamertag, setOppGamertag] = useState("");
  const [roomCode, setRoomCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");
  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);
  const digit4Ref = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/smash-arena/setup");
  };

  const handleBackspaceFocusPrevious = (e, currentRef, previousRef) => {
    if (e.key === "Backspace" && e.target.value === "") {
      e.preventDefault();
      if (previousRef) {
        previousRef.current.focus();
      }
    }
  };

  const handleInputChange = (e, setter, nextRef) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      setter(value);
      if (value && nextRef) {
        nextRef.current.focus();
      }
    }
  };

  const handleJoinRoom = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const combinedPasscode = `${digit1}${digit2}${digit3}${digit4}`;
      const roomCodeUppoer = roomCode.toUpperCase();
      const res = await axios.post(
        "https://bracket-buddy.onrender.com/join-room",
        {
          room_code: roomCodeUppoer,
          gamertag: oppGamertag,
          passcode: combinedPasscode,
        }
      );
      localStorage.setItem("access_token", res.data.access_token);
      navigate(`/rooms/${roomCodeUppoer}`);
    } catch (e) {
      setErrorMsg(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoinRoomClick = () => {
    setIsModalOpen(true);
  };

  const currPasscode = `${digit1}${digit2}${digit3}${digit4}`;

  return (
    <>
      {isLoading ? (
        <div
          className="spinner-border text-primary"
          role="status"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50vh",
            left: "50vw",
          }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="container-sm selection-assistant-info-container">
          <h1 className="mt-5 tab-title-selection-assistant">Let's Smash</h1>
          <p className="selection-assistant-desc">
            Enhance your match experience with seamless organization and real
            time chatting, all while making sure both players enjoy the benefits
            of fair and organized competition.
          </p>

          <div className="button-container selection-buttons">
            <button
              type="button"
              className="btn btn-outline-dark selection-button"
              onClick={handleSubmit}
            >
              Get Started
            </button>
            <button
              type="button"
              className="btn btn-outline-primary selection-button"
              data-toggle="modal"
              data-target="joinRoomModal"
              onClick={handleJoinRoomClick}
            >
              Join Room
            </button>
          </div>

          <div
            className={isModalOpen ? "modal fade show d-block" : "modal fade"}
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: isModalOpen ? "rgba(0,0,0,0.5)" : "" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="modal-title">Join A Room</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setIsModalOpen(false)}
                    aria-label="Close"
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <form onSubmit={handleJoinRoom} className="join-room-form">
                  <div className="input-group input-group-join-room">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Gamertag
                      </span>
                    </div>
                    <input
                      id="gamertag"
                      value={oppGamertag}
                      onChange={(e) => setOppGamertag(e.target.value)}
                    />
                  </div>
                  <div className="input-group input-group-join-room">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Room Code
                      </span>
                    </div>
                    <input
                      id="roomCode"
                      value={roomCode}
                      onChange={(e) => setRoomCode(e.target.value)}
                    />
                  </div>
                  <div className="passcode-label-container">
                    <div className="passcode-label">Passcode</div>
                  </div>
                  <div className="input-group  input-group-join-room passcode-container">
                    <input
                      ref={digit1Ref}
                      type="tel"
                      maxLength="1"
                      value={digit1}
                      onChange={(e) =>
                        handleInputChange(e, setDigit1, digit2Ref)
                      }
                      onKeyDown={(e) =>
                        handleBackspaceFocusPrevious(e, digit1Ref, null)
                      }
                      className="passcode-input"
                      inputMode="numeric"
                    />
                    <input
                      ref={digit2Ref}
                      type="tel"
                      maxLength="1"
                      value={digit2}
                      onChange={(e) =>
                        handleInputChange(e, setDigit2, digit3Ref)
                      }
                      onKeyDown={(e) =>
                        handleBackspaceFocusPrevious(e, digit2Ref, digit1Ref)
                      }
                      className="passcode-input"
                      inputMode="numeric"
                    />
                    <input
                      ref={digit3Ref}
                      type="tel"
                      maxLength="1"
                      value={digit3}
                      onChange={(e) =>
                        handleInputChange(e, setDigit3, digit4Ref)
                      }
                      onKeyDown={(e) =>
                        handleBackspaceFocusPrevious(e, digit3Ref, digit2Ref)
                      }
                      className="passcode-input"
                      inputMode="numeric"
                    />
                    <input
                      ref={digit4Ref}
                      type="tel"
                      maxLength="1"
                      value={digit4}
                      onChange={(e) => handleInputChange(e, setDigit4, null)}
                      onKeyDown={(e) =>
                        handleBackspaceFocusPrevious(e, digit4Ref, digit3Ref)
                      }
                      className="passcode-input"
                      inputMode="numeric"
                    />
                  </div>
                  <div className="button-container">
                    <button
                      className="btn btn-outline-primary join-button"
                      type="submit"
                    >
                      Join
                    </button>
                  </div>
                  {errorMsg ? (
                    <div className="error-msg">{errorMsg}</div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectionAssistant;
