import React, { useEffect, useState } from "react";
import "./GameDetails.css";

const GameDetails = ({
  gameNo,
  forPlayer,
  otherPlayer,
  gameStage,
  rules,
  handleGameWinner,
  gameWinner,
  showWinner,
  gamertag,
  maxMatchSteps,
  matchEnd,
  handleGameEnd,
}) => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedPlayerName, setSelectedPlayerName] = useState("");
  const gameKey = `game${gameNo}`;

  useEffect(() => {
    if (
      (showWinner && !gameWinner) ||
      (showWinner && !gameWinner[`game${gameNo}`])
    ) {
      setTimeout(() => {
        setSelectedCharacter(null);
        setIsConfirmed(false);
        setSelectedPlayerName("");
      }, 2500);
    }
  }, [showWinner, gameWinner]);

  const handleWinnerSelect = (selectedGamertag) => {
    if (!isConfirmed) {
      setSelectedCharacter((prevSelectedCharacter) => {
        return prevSelectedCharacter === selectedGamertag
          ? null
          : selectedGamertag;
      });
    }
  };

  const allStages = rules.rules.starterStages.concat(
    rules.rules.counterpickStages
  );

  const confirmWinner = () => {
    handleGameWinner(selectedCharacter);
    setIsConfirmed(true);
    setSelectedPlayerName(selectedCharacter);
  };

  const renderCharacterCard = (player) => (
    <div
      key={player["gamertag"]}
      className={`character-card game-details ${
        selectedCharacter === player["gamertag"] ? "selected" : ""
      } ${selectedCharacter ? "unclickable" : ""}`}
      style={{
        width: "8rem",
        height: "9rem",
      }}
      onClick={() => handleWinnerSelect(player["gamertag"])}
    >
      <img
        className="character-image"
        src={player[`character_${gameKey}`].icon}
        alt={player[`character_${gameKey}`].name}
        style={{
          width: "70%",
          height: "60%",
          display: "block",
          margin: "auto",
          marginTop: "0.4rem",
        }}
      />
      <div className="card-body p-1">
        <p className="character-name">{player["gamertag"]}</p>
      </div>
    </div>
  );

  const firstPlayer =
    forPlayer["gamertag"] === gamertag ? forPlayer : otherPlayer;
  const secondPlayer =
    forPlayer["gamertag"] === gamertag ? otherPlayer : forPlayer;

  return (
    <div className="game-details-container">
      <div className="game-characters">
        {firstPlayer[`character_${gameKey}`] &&
          renderCharacterCard(firstPlayer)}
        <div className="vs-icon">
          <div className="vs">vs</div>
        </div>
        {secondPlayer[`character_${gameKey}`] &&
          renderCharacterCard(secondPlayer)}
      </div>

      {gameStage[`game${gameNo}`] && (
        <div className="game-stage-details">
          <div className="game-stage-details">
            Stage:{" "}
            {allStages
              .filter((stage) => gameStage[`game${gameNo}`].includes(stage.id))
              .map((stage) => (
                <div key={stage.id} className="game-stage-name">
                  {stage.name}
                </div>
              ))}
          </div>
        </div>
      )}
      {gameStage[`game${gameNo}`] && !selectedCharacter && !showWinner && (
        <div className="game-instructions">
          Report winner by clicking on the appropriate character card
        </div>
      )}

      {selectedCharacter && !isConfirmed && (
        <button
          className="confirm-button btn btn-outline-secondary"
          onClick={confirmWinner}
        >
          Confirm
        </button>
      )}

      {isConfirmed && !showWinner && (
        <div className="selected-player-message">
          You selected: <strong>{selectedPlayerName}</strong>
        </div>
      )}

      {showWinner ? (
        gameWinner ? (
          Object.keys(gameWinner) && gameWinner[`game${gameNo}`] ? (
            <>
              <div className={"winner-message winner-match"}>
                {`${gameWinner[`game${gameNo}`]} wins`}
              </div>
              <button
                className="btn btn-outline-secondary game-end next-step"
                onClick={handleGameEnd}
              >
                {matchEnd ? "End Match" : "Next Game"}
              </button>
            </>
          ) : (
            <>
              <div className={"winner-message winner-mismatch"}>
                Different winners were selected. Please coordinate with your
                opponent.
              </div>
            </>
          )
        ) : (
          <>
            <div className={"winner-message winner-mismatch"}>
              Different winners were selected. Please coordinate with your
              opponent.
            </div>
          </>
        )
      ) : (
        <div>
          {selectedCharacter && isConfirmed
            ? "Waiting on opponent to select winner..."
            : ""}
        </div>
      )}
    </div>
  );
};

export default GameDetails;
