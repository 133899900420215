import React from 'react';
import RegistrationForm from './RegistrationForm'
import { Link } from "react-router-dom";

const Register = (props) => {
    return (
        <>
            <h1 className='mt-5'>Create Account</h1>
            <RegistrationForm setAccessToken={props.setAccessToken} />
            <p className='page-info'>Already on Bracket Buddy? <Link to='/login'>Sign in</Link></p>
        </>
    )
};

export default Register;