import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./LoginForm.css";

const LoginForm = (props) => {
  const INITIAL_STATE = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(INITIAL_STATE);
  const [incorrectInfo, setIncorrectInfo] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://bracket-buddy.onrender.com/login",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      props.setAccessToken(res.data.access_token);
      navigate("/");
    } catch (e) {
      setIncorrectInfo(true);
      console.error("Error during login:", e);
    }
    setFormData(INITIAL_STATE);
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <label htmlFor="email" className="col-form-label">
              Email
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
            />
            <label htmlFor="password" className="col-form-label">
              Password
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="form-control"
            />

            {incorrectInfo ? (
              <div class="alert alert-danger incorrect-info" role="alert">
                Incorrect email or password. Please try again.
              </div>
            ) : null}
            <button
              type="submit"
              className="btn btn-outline-primary login-form-button"
            >
              Sign In
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
