import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";

const Login = (props) => {
    return (
        <div>
            <h1 className='mt-5'>Sign in</h1>
            <LoginForm setAccessToken={props.setAccessToken} />
            <p className="page-info">New to Bracket Buddy? Create an account <Link to='/register'>Join Now</Link></p>
        </div>
    );
};

export default Login;