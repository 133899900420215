import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./PlayerProfile.css";
import "./PlayerSummary.css";
import CharacterCard from "./CharacterCard";
import TourneyCard from "./TourneyCard";

const PlayerSummary = ({
  playerid,
  changeTourneyPage,
  changeTab,
  pageInfo,
  nodes,
  activeTab,
  lastTourney,
  tourneyOutcome,
}) => {
  const [characters, setCharacters] = useState([]);
  const [playerStats, setPlayerStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabSelect = (tab) => {
    changeTab(tab);
  };

  const daysSinceEvent = (timestamp) => {
    const eventDate = new Date(timestamp * 1000);
    const currDate = new Date();
    const diff = currDate - eventDate;
    const daysSince = Math.floor(diff / (1000 * 60 * 60 * 24));
    return daysSince;
  };

  useEffect(() => {
    const getCharacterUsage = async () => {
      try {
        setIsLoading(true);
        const res = await axios.post(
          "https://bracket-buddy.onrender.com/character_usage",
          {
            playerid,
          }
        );
        if (res.data) {
          setCharacters(res.data.character_usage);
        } else {
          console.error("Invalid API response:", res);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getCharacterUsage();
  }, [playerid]);

  useEffect(() => {
    const getUserStats = async () => {
      try {
        setIsLoading(true);
        const res = await axios.post(
          "https://bracket-buddy.onrender.com/player_summary",
          { playerid },
          { withCredentials: true }
        );
        if (res.data) {
          setPlayerStats(res.data);
        } else {
          console.error("Invalid API response:", res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setPlayerStats(null);
      } finally {
        setIsLoading(false);
      }
    };
    getUserStats();
  }, [playerid]);

  function formatNumbers(num) {
    return num ? num.toLocaleString() : "N/A";
  }

  const getPageNumList = () => {
    const pageNumList = [];
    const maxButtons = 3;

    if (pageInfo.totalPages <= maxButtons) {
      for (let i = 1; i <= pageInfo.totalPages; i++) {
        pageNumList.push(i);
      }
    } else {
      const startPage = Math.max(1, pageInfo.page - 2);
      const endPage = Math.min(pageInfo.totalPages, startPage + maxButtons - 1);

      if (startPage > 1) {
        pageNumList.push(1, "...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumList.push(i);
      }

      if (endPage < pageInfo.totalPages) {
        pageNumList.push("...", pageInfo.totalPages);
      }
    }

    return pageNumList;
  };

  return (
    <>
      <div className="container mt-4 player-summary-container">
        <div className="card custom-card">
          <div className="card-body">
            <ul className="nav nav-pills custom-tabs mb-3">
              <li className="nav-item">
                <button
                  className={`nav-link player-profile-tab ${
                    activeTab === "stats" ? "active" : ""
                  }`}
                  onClick={() => handleTabSelect("stats")}
                >
                  Stats
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link player-profile-tab ${
                    activeTab === "characters" ? "active" : ""
                  }`}
                  onClick={() => handleTabSelect("characters")}
                >
                  Characters
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link player-profile-tab ${
                    activeTab === "tournaments" ? "active" : ""
                  }`}
                  onClick={() => handleTabSelect("tournaments")}
                >
                  Tournaments
                </button>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className={`tab-pane fade ${
                  activeTab === "stats" ? "show active" : ""
                }`}
              >
                {isLoading || !playerStats ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "fixed",
                      top: "50vh",
                      left: "50vw",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="stats-table-header">Rank</td>
                        <td className="stats-table-value">
                          {formatNumbers(playerStats.rank)}{" "}
                          <span
                            style={{
                              color: "#0067da",
                              fontStyle: "italic",
                              marginLeft: "0.4rem",
                            }}
                          >
                            of {formatNumbers(playerStats.num_players)}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="stats-table-header">Elo Rating</td>
                        <td className="stats-table-value">
                          {formatNumbers(playerStats.elo_rating)}
                        </td>
                      </tr>
                      <tr>
                        <td className="stats-table-header">Sets Record</td>
                        <td
                          className="stats-table-value"
                          style={{
                            color: playerStats.num_wins
                              ? playerStats.num_wins >
                                playerStats.num_sets - playerStats.num_wins
                                ? "green"
                                : playerStats.num_wins <
                                  playerStats.num_sets - playerStats.num_wins
                                ? "red"
                                : "#0067da"
                              : "#0067da",
                          }}
                        >
                          {playerStats.num_wins
                            ? `(${formatNumbers(
                                playerStats.num_wins
                              )} - ${formatNumbers(
                                playerStats.num_sets - playerStats.num_wins
                              )})`
                            : "N/A"}
                        </td>
                      </tr>
                      <tr>
                        <td className="stats-table-header">
                          Most Played Character
                        </td>
                        <td className="stats-table-value">
                          {playerStats.characters &&
                          playerStats.characters.length > 0 ? (
                            <img
                              src={playerStats.characters[0].stock_icon}
                              alt="Character Icon"
                              style={{ width: "1.7rem", height: "1.7rem" }}
                            />
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="stats-table-header">Singles Attended</td>
                        <td className="stats-table-value">
                          {playerStats.num_events}
                        </td>
                      </tr>
                      <tr>
                        <td className="stats-table-header">Last Tournament</td>
                        <td className="stats-table-value">
                          <div className="last-event">
                            {!lastTourney ? (
                              "N/A"
                            ) : lastTourney.name && lastTourney.slug ? (
                              <>
                                <a
                                  href={`https://www.start.gg/${lastTourney.slug}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {lastTourney.name}
                                </a>
                                <span>
                                  {" "}
                                  ({daysSinceEvent(lastTourney.startAt)} days
                                  ago)
                                </span>
                              </>
                            ) : lastTourney.name ? (
                              lastTourney.name
                            ) : lastTourney.slug ? (
                              <>
                                <a
                                  href={`https://www.start.gg/${lastTourney.slug}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View last event
                                </a>
                                <span>
                                  {" "}
                                  ({daysSinceEvent(lastTourney.startAt)} days
                                  ago)
                                </span>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "characters" ? "show active" : ""
                }`}
              >
                {isLoading || !characters ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "fixed",
                      top: "50vh",
                      left: "50vw",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div
                    className="char-card-container"
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    {characters.length > 0 ? (
                      characters
                        .slice(0, 3)
                        .map((each, index) => (
                          <CharacterCard
                            each={each}
                            index={index}
                            key={each.id}
                          />
                        ))
                    ) : (
                      <p className="summary-characters-msg">
                        User does not currently have any character data
                        available.
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "tournaments" ? "show active" : ""
                }`}
              >
                {nodes && nodes.length > 0 ? (
                  nodes.map((event, index) => (
                    <TourneyCard
                      key={event.id}
                      playerid={playerid}
                      event={event}
                      tourneySets={
                        tourneyOutcome[index]?.data?.tournaments?.nodes?.[0]
                          ?.events?.[0]?.sets?.nodes || []
                      }
                    />
                  ))
                ) : (
                  <p>No tournament information available.</p>
                )}
                {pageInfo && (
                  <div className="page-buttons">
                    <nav className="page-navigation-container">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            pageInfo.page === 1 ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => changeTourneyPage(pageInfo.page - 1)}
                            disabled={pageInfo.page === 1}
                          >
                            {"<"}
                          </button>
                        </li>
                        {getPageNumList().map((pageNum, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              pageNum === "..." ? "disabled" : ""
                            }`}
                          >
                            {pageNum === "..." ? (
                              <span className="page-link">...</span>
                            ) : (
                              <button
                                className={`page-link ${
                                  pageNum === pageInfo.page ? "active" : ""
                                }`}
                                onClick={() => changeTourneyPage(pageNum)}
                              >
                                {formatNumbers(pageNum)}
                              </button>
                            )}
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            pageInfo.page === pageInfo.totalPages
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => changeTourneyPage(pageInfo.page + 1)}
                            disabled={pageInfo.page === pageInfo.totalPages}
                          >
                            {">"}
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerSummary;
