import "./App.css";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./Home";
import NavBar from "./NavBar";
import PlayerRankings from "./PlayerRankings";
import Login from "./Login";
import MyStats from "./MyStats";
import Register from "./Register";
import useAccessToken from "./hooks/useAccessToken";
import PlayerProfile from "./PlayerProfile";
import SelectionAssistant from "./SelectionAssistant";
import SelectionAssistantSetup from "./SelectionAssistantSetup";
import Room from "./Room";
import VerifyEmail from "./VerifyEmail";
import SearchResults from "./SearchResults";
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function App() {
  const { accessToken, removeAccessToken, setAccessToken } = useAccessToken();

  const [isLogged, setIsLogged] = useState(false);
  const access_token = localStorage.getItem("access_token");

  // useEffect(() => {
  //   if (accessToken) {
  //     try {
  //       const decodedToken = jwtDecode(accessToken);
  //       setIsLogged(!!decodedToken.identity);
  //     } catch (error) {
  //       console.error("Invalid token", error);
  //       setIsLogged(false);
  //     }
  //   } else {
  //     setIsLogged(false);
  //   }
  // }, [accessToken]);

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar removeAccessToken={removeAccessToken} />
        {!isLogged ? (
          <Routes>
            <Route path="/smash-arena" element={<SelectionAssistant />} />
            <Route
              path="/smash-arena/setup"
              element={<SelectionAssistantSetup />}
            />
            <Route path="/rooms/:roomCode" element={<Room />} />
            <Route path="/player-rankings" element={<PlayerRankings />} />
            <Route path="/players/:playerid" element={<PlayerProfile />} />
            <Route path="/" element={<Home />} />
            <Route path="/mystats" element={<MyStats />}>
              <Route
                path="/mystats"
                element={<Navigate replace to="/register" />}
              />
              <Route
                path="/mystats"
                element={<Navigate replace to="/login" />}
              />
            </Route>
            <Route
              path="/register"
              element={<Register setAccessToken={setAccessToken} />}
            />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route
              path="/login"
              element={<Login setAccessToken={setAccessToken} />}
            >
              <Route
                path="/login"
                element={<Navigate replace to="/register" />}
              />
            </Route>
            <Route path="/search" element={<SearchResults />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/selection-assistant"
              element={<SelectionAssistant />}
            />
            <Route
              path="/selection-assistant/setup"
              element={<SelectionAssistantSetup />}
            />
            <Route path="/rooms/:roomcode" element={<Room />} />
            <Route path="/player-rankings" element={<PlayerRankings />} />
            <Route path="/mystats" element={<MyStats />} />
            <Route path="/players/:playerid" element={<PlayerProfile />} />
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<SearchResults />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
