import React, { useEffect } from "react";
import "./RPS.css";
import RPSCard from "./RPSCard";

const RPS = ({
  onRPSSelect,
  opponentGamertag,
  rpsWinner,
  creatorGamertag,
  isCreator,
  showRPS,
  forPlayer,
  otherPlayer,
  selectedRPS,
  hideCard,
  moveToNextStep,
}) => {
  useEffect(() => {
    if (rpsWinner) {
      setTimeout(() => moveToNextStep(), 2500);
    }
  }, [rpsWinner]);

  const handleRPSSelect = (option) => {
    if (!selectedRPS) {
      onRPSSelect(option);
    }
  };

  return (
    <>
      <h1 className="mt-5 rps-title">Rock-Paper-Scissors</h1>
      <div className="rps-options d-flex">
        <div className="rps-placeholder" style={{ height: "10rem" }}></div>

        <RPSCard
          keyName={"rock"}
          className={`rock`}
          handleRPSSelect={handleRPSSelect}
          imgSRC={process.env.PUBLIC_URL + "/rock.png"}
          selectedRPS={selectedRPS}
          rpsWinner={rpsWinner}
          isCreator={isCreator}
          creatorGamertag={creatorGamertag}
          opponentGamertag={opponentGamertag}
        />
        <RPSCard
          keyName={"paper"}
          className={`paper`}
          handleRPSSelect={handleRPSSelect}
          imgSRC={process.env.PUBLIC_URL + "/paper.png"}
          selectedRPS={selectedRPS}
          rpsWinner={rpsWinner}
          isCreator={isCreator}
          creatorGamertag={creatorGamertag}
          opponentGamertag={opponentGamertag}
        />
        <RPSCard
          keyName={"scissors"}
          className={`scissors`}
          handleRPSSelect={handleRPSSelect}
          imgSRC={process.env.PUBLIC_URL + "/scissors.png"}
          selectedRPS={selectedRPS}
          rpsWinner={rpsWinner}
          isCreator={isCreator}
          creatorGamertag={creatorGamertag}
          opponentGamertag={opponentGamertag}
        />
      </div>
      <div className="rps-decision">
        <div
          className={`card rps-card player-selection ${
            hideCard ? "hide-card" : "show-card"
          }
          ${
            rpsWinner
              ? isCreator
                ? rpsWinner === creatorGamertag
                  ? "rps-win"
                  : "rps-lose"
                : rpsWinner === opponentGamertag
                ? "rps-win"
                : "rps-lose"
              : ""
          }`}
        >
          {isCreator ? (
            selectedRPS ? (
              <div className="rps-card">
                <img
                  src={process.env.PUBLIC_URL + `/${selectedRPS}.png`}
                  className="card-img-top"
                  alt={selectedRPS}
                />
                <div className="card-body rps-card-body">{creatorGamertag}</div>
              </div>
            ) : (
              <div className="card rps-card hide-card">
                <div className="rps-card rps-waiting hide-card"></div>
              </div>
            )
          ) : selectedRPS ? (
            <div className="rps-card">
              <img
                src={process.env.PUBLIC_URL + `/${selectedRPS}.png`}
                className="card-img-top"
                alt={selectedRPS}
              />
              <div className="card-body rps-card-body">{opponentGamertag}</div>
            </div>
          ) : (
            <div className="card rps-card hide-card">
              <div className="rps-card rps-waiting hide-card"></div>
            </div>
          )}
        </div>
        <div className="card rps-card hide-card">
          <div className="rps-card rps-waiting hide-card"></div>
        </div>
        <div
          className={`card rps-card opp-selection ${
            hideCard ? "hide-card" : "show-card"
          }
          ${
            rpsWinner
              ? isCreator
                ? rpsWinner === creatorGamertag
                  ? "rps-lose"
                  : "rps-win"
                : rpsWinner === opponentGamertag
                ? "rps-lose"
                : "rps-win"
              : ""
          }`}
        >
          {!showRPS ? (
            <div className="rps-card rps-waiting">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7rem"
                height="7rem"
                fill="currentColor"
                className="bi bi-question-lg rps-waiting"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"
                />
              </svg>
              <div className="card-body rps-card-body opp-selecting">
                Opponent selecting...
              </div>
            </div>
          ) : isCreator ? (
            <div className="rps-card">
              <img
                src={process.env.PUBLIC_URL + `/${otherPlayer.option}.png`}
                className="card-img-top"
                alt={otherPlayer.option}
              />
              <div className="card-body rps-card-body">{opponentGamertag}</div>
            </div>
          ) : (
            <div className="rps-card">
              <img
                src={process.env.PUBLIC_URL + `/${forPlayer.option}.png`}
                className="card-img-top"
                alt={otherPlayer.option}
              />
              <div className="card-body rps-card-body">{creatorGamertag}</div>
            </div>
          )}
        </div>
      </div>
      {!selectedRPS && <p className="select-msg">Select an option</p>}
      {selectedRPS && !showRPS && (
        <p className="select-msg">Waiting for opponent...</p>
      )}
      <div className="draw-msg">
        {showRPS && !rpsWinner ? "It's A Draw!" : ""}
      </div>
      <div className="outcome-msg">
        {showRPS && rpsWinner ? (
          isCreator ? (
            rpsWinner === creatorGamertag ? (
              <div className="rps-winner">You win!</div>
            ) : (
              <div className="rps-loser">You lose!</div>
            )
          ) : rpsWinner === opponentGamertag ? (
            <div className="rps-winner">You win!</div>
          ) : (
            <div className="rps-loser">You lose!</div>
          )
        ) : (
          ""
        )}
        {rpsWinner ? (
          <div className="game-over-next-step">Moving to next step...</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default RPS;
