import React from "react";
import "./RPSCard.css";

const RPSCard = ({
  keyName,
  handleRPSSelect,
  imgSRC,
  selectedRPS,
  rpsWinner,
  isCreator,
  creatorGamertag,
  opponentGamertag,
}) => {
  return (
    <div
      className={`card rps-card ${
        selectedRPS === keyName ? "option-selected" : ""
      }
      ${keyName} ${
        selectedRPS !== null && selectedRPS !== keyName ? "not-selected" : ""
      }
      ${
        rpsWinner
          ? isCreator
            ? rpsWinner === creatorGamertag
              ? "rps-win"
              : "rps-lose"
            : rpsWinner === opponentGamertag
            ? "rps-win"
            : "rps-lose"
          : ""
      }`}
      key={keyName}
      onClick={() => handleRPSSelect(keyName)}
    >
      <img src={imgSRC} className="card-img-top" alt={keyName} />
      <div className="card-body rps-card-body">{keyName}</div>
    </div>
  );
};

export default RPSCard;
