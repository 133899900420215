import React, { useState } from "react";

const useAccessToken = () => {
    const getAccessToken = () => {
        const userAccessToken = localStorage.getItem('access_token');
        return userAccessToken && userAccessToken
    }

    const [accessToken, setAccessToken] = useState(getAccessToken())

    const saveAccessToken = (userAccessToken) => {
        localStorage.setItem('access_token', userAccessToken);
        setAccessToken(userAccessToken)
    }

    const removeAccessToken = () => {
        localStorage.removeItem('access_token');
        setAccessToken(null)
    }

    return (
        { setAccessToken: saveAccessToken, accessToken, removeAccessToken }
    )
}

export default useAccessToken;