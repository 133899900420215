import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const res = await axios.get(
          `https://bracket-buddy.onrender.com/verify-email/${token}`
        );
        setVerified(true);
        setMessage("Verification successful. Redirecting...");
        setTimeout(() => navigate("/"), 3000);
      } catch (e) {
        setMessage(
          "Verification failed. The link may be expired. Please register again."
        );
      }
    };
    verifyEmail();
  }, [token, navigate]);

  return (
    <div>
      {verified ? (
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      ) : (
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
