import React, { useState, useEffect } from "react";
import SelectionForm from "./SelectionForm";
import "./SelectionAssistantSetup.css";
import { useNavigate } from "react-router-dom";

const SelectionAssistantSetup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    roomCreator: "",
    setType: "",
    selectionOrder: "",
    starterStages: [],
    counterpickStages: [],
    stageClause: "",
    game1NumBans: { player1Num: 0, player2Num: 0 },
    nextGameBans: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStarterStageChange = (selectedStages) => {
    setFormData({
      ...formData,
      starterStages: selectedStages,
    });
  };
  const handleCounterStageChange = (selectedStages) => {
    setFormData({
      ...formData,
      counterpickStages: selectedStages,
    });
  };

  return (
    <div className="selection-forms-content">
      <div className="selection-forms-container">
        <h1 className="mt-5 selection-form-page-title">
          Ruleset Configuration
        </h1>
        <div className="selection-forms">
          <SelectionForm
            handleChange={handleChange}
            currSetType={formData.setType}
            currSelectionOrder={formData.selectionOrder}
            onStarterStageChange={handleStarterStageChange}
            onCounterStageChange={handleCounterStageChange}
            currStageClause={formData.stageClause}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectionAssistantSetup;
