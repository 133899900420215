import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import SocialIcon from "./SocialIcon";
import PlayerSummary from "./PlayerSummary";
import "./TourneyCard.css";

const TourneyCard = ({ event, tourneySets, playerid }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear().toString();
    const month = date.toLocaleString("default", { month: "short" });
    let day = date.getDate().toString().padStart(2, "0");

    return `${month}-${day}-${year}`;
  };

  const isSetWinner = (eventSet) => {
    if (!eventSet || !eventSet.slots || eventSet.slots.length === 0) {
      return false;
    }

    let entrantNum = null;

    const participantUser = eventSet.slots[0]?.entrant?.participants?.[0]?.user;
    if (
      participantUser &&
      participantUser.id.toString() === playerid.toString()
    ) {
      entrantNum = 1;
    } else {
      entrantNum = 2;
    }

    const winnerId = eventSet.winnerId?.toString();
    const entrantId = eventSet.slots[entrantNum - 1]?.entrant?.id?.toString();

    if (winnerId && entrantId && winnerId === entrantId) {
      return true;
    } else {
      return false;
    }
  };

  const getEventRecord = (tourneySets) => {
    const numSets = tourneySets.length;
    const setResults = tourneySets.map((currSet) => {
      return isSetWinner(currSet);
    });
    const winCount = setResults.reduce((count, currSetRes) => {
      if (currSetRes === true) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);

    const winRate = (numSets, winCount) => {
      if (winCount === 0) {
        return "0%";
      } else if (winCount === numSets) {
        return "100%";
      } else {
        return `${Math.round((winCount / numSets) * 100)}%`;
      }
    };
    return `${winCount} - ${numSets - winCount} / ${winRate(
      numSets,
      winCount
    )}`;
  };

  const getWinningOpps = (tourneySets) => {
    let listWinningOpps = [];

    tourneySets.forEach((currSet) => {
      if (!currSet || !currSet.slots || currSet.slots.length < 2) {
        return;
      }

      let oppEntrantNum = null;

      const firstParticipant =
        currSet.slots[0]?.entrant?.participants?.[0]?.user;
      const secondParticipant =
        currSet.slots[1]?.entrant?.participants?.[0]?.user;

      if (
        firstParticipant &&
        firstParticipant.id.toString() === playerid.toString()
      ) {
        oppEntrantNum = 2;
      } else if (secondParticipant) {
        oppEntrantNum = 1;
      }

      if (
        oppEntrantNum &&
        currSet.winnerId.toString() !== event.userEntrant.id.toString()
      ) {
        const oppEntrant = currSet.slots[oppEntrantNum - 1]?.entrant;
        const oppParticipant = oppEntrant?.participants?.[0];

        if (oppParticipant && oppParticipant.user && oppParticipant.user.id) {
          const oppElement = oppParticipant.prefix ? (
            <a
              href={`https://www.bracket-buddy.com/players/${oppParticipant.user.id}`}
              target="_blank"
              rel="noreferrer"
            >{`${oppParticipant.prefix} | ${oppParticipant.gamerTag}`}</a>
          ) : (
            <a
              href={`https://www.bracket-buddy.com/players/${oppParticipant.user.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {oppParticipant.gamerTag}
            </a>
          );
          listWinningOpps.push(oppElement);
        } else if (oppParticipant) {
          const oppElement = oppParticipant.prefix ? (
            <div>{`${oppParticipant.prefix} | ${oppParticipant.gamerTag}`}</div>
          ) : (
            <div>{oppParticipant.gamerTag}</div>
          );
          listWinningOpps.push(oppElement);
        }
      }
    });

    return listWinningOpps;
  };

  const getListCharsUsed = (tourneySets) => {
    let charsUsedList = [];

    tourneySets.forEach((currSet) => {
      if (currSet.games) {
        currSet.games.forEach((currGame) => {
          const selections = currGame.selections;
          if (
            selections &&
            selections.length > 0 &&
            selections[0]?.entrant &&
            selections[1]?.entrant &&
            selections[0]?.character &&
            selections[1]?.character
          ) {
            const userEntrantId = event.userEntrant.id.toString();
            const firstSelectionEntrantId = selections[0].entrant.id.toString();
            const secondSelectionEntrantId =
              selections[1].entrant.id.toString();

            if (firstSelectionEntrantId === userEntrantId) {
              charsUsedList.push(selections[0].character.images[1].url);
            } else if (secondSelectionEntrantId === userEntrantId) {
              charsUsedList.push(selections[1].character.images[1].url);
            }
          }
        });
      }
    });

    if (charsUsedList.length === 0) {
      return [];
    }

    const charFrequency = charsUsedList.reduce((accum, charURL) => {
      accum[charURL] = (accum[charURL] || 0) + 1;
      return accum;
    }, {});

    const sortedChars = Object.entries(charFrequency).sort(
      (a, b) => b[1] - a[1]
    );
    return sortedChars.map((charURL) => charURL[0]);
  };

  const getSuffix = (num) => {
    const case1 = num % 10;
    const case2 = num % 100;

    if (case1 === 1 && case2 !== 11) {
      return "st";
    } else if (case1 === 2 && case2 !== 12) {
      return "nd";
    } else if (case1 === 3 && case2 !== 13) {
      return "rd";
    } else {
      return "th";
    }
  };

  const getInitialSeed = (seedList) => {
    if (!seedList || seedList.length === 0) {
      return "N/A";
    }

    const lowestPhaseOrder = seedList.reduce((acc, curr) => {
      return acc.phase.phaseOrder < curr.phase.phaseOrder ? acc : curr;
    });

    return lowestPhaseOrder.seedNum;
  };

  return (
    <div className="card mb-3 tourney-card">
      {event.tournament.images && event.tournament.images.length > 0 ? (
        <img
          src={event.tournament.images[0].url}
          className="img-fluid rounded event-img"
          alt={event.name}
        />
      ) : (
        <img
          src={process.env.PUBLIC_URL + "/SSB_logo.png"}
          alt={event.name}
          className="img-fluid rounded event-img"
        />
      )}
      <div className="card-body-tourney">
        <div className="card-title tourney-card-title">
          <a
            href={`https://www.start.gg/${event.slug}`}
            target="_blank"
            rel="noreferrer"
            className="tourney-card-link"
          >
            {event.tournament.name} - {event.name}
          </a>
        </div>
        <div className="card-stats-container">
          <div className="card-stat-group card-date-group">
            <span className="card-date-title">Date</span>
            <div className="card-date">{formatDate(event.startAt)}</div>
          </div>
          <div className="card-stat-group">
            <span className="card-record-title">Sets</span>
            <div className="card-record">{getEventRecord(tourneySets)}</div>
          </div>
          {getListCharsUsed(tourneySets) &&
            getListCharsUsed(tourneySets).length > 0 && (
              <div className="card-stat-group">
                <span className="card-characters-title">Characters</span>
                <div className="card-characters">
                  {getListCharsUsed(tourneySets).map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt="Character Icon"
                      className="tourney-card-char-icon"
                    />
                  ))}
                </div>
              </div>
            )}
          <div className="card-stat-group">
            <span className="card-lost-to-title">Lost To</span>
            <div className="card-lost-to">
              {getWinningOpps(tourneySets).length > 0
                ? getWinningOpps(tourneySets).map((opp, index) => (
                    <div key={index} className="tourney-card-opp-name">
                      {opp}
                    </div>
                  ))
                : "N/A"}
            </div>
          </div>
          <div className="card-stat-group">
            <span className="card-seed-title">Seed</span>
            <div className="card-seed">
              {getInitialSeed(event.userEntrant.seeds)}
            </div>
          </div>
        </div>
      </div>
      <div className="card-overall-performance">
        <div className="card-placement">
          {event.userEntrant.standing &&
          event.userEntrant.standing.placement ? (
            <>
              {event.userEntrant.standing.placement}
              <sup>{getSuffix(event.userEntrant.standing.placement)}</sup>
            </>
          ) : (
            "N/A"
          )}{" "}
        </div>
        <div className="card-placement-others-entrants">
          of {event.numEntrants.toLocaleString()}
        </div>
      </div>
    </div>
  );
};

export default TourneyCard;
