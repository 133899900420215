import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./SelectionForm.css";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import useAccessToken from "./hooks/useAccessToken";

const SelectionForm = ({
  handleChange,
  currSetType,
  currSelectionOrder,
  onStarterStageChange,
  onCounterStageChange,
  currStageClause,
  formData,
  setFormData,
}) => {
  const [stages, setStages] = useState([]);
  const [selectedStarterStages, setSelectedStarterStages] = useState([]);
  const [starterStagesError, setStarterStagesError] = useState("");
  const [selectedCounterStages, setSelectedCounterStages] = useState([]);
  const [selectedModalStages, setSelectedModalStages] = useState([]);
  const [selectedModalCounterStages, setSelectedModalCounterStages] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showStageModal, setShowStageModal] = useState(false);
  const [showCounterStageModal, setShowCounterStageModal] = useState(false);
  const stageModalRef = useRef();
  const counterStageModalRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStages, setFilteredStages] = useState([]);
  const [numBansPlayer1, setNumBansPlayer1] = useState(0);
  const [numBansPlayer2, setNumBansPlayer2] = useState(0);
  const [numBansWinner, setNumBansWinner] = useState(0);
  const [player1Bans, setPlayer1Bans] = useState(0);
  const [winnerBans, setWinnerBans] = useState(0);
  const [creatorGamertag, setCreatorGamertag] = useState("");
  const [passcode, setPasscode] = useState("");
  const [passcodeError, setPasscodeError] = useState("");
  const [roomCode, setRoomCode] = useState("");
  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");
  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);
  const digit4Ref = useRef(null);
  const navigate = useNavigate();
  const { accessToken, removeAccessToken, setAccessToken } = useAccessToken();
  const [isLogged, setIsLogged] = useState(false);
  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    const getStages = async () => {
      try {
        const res = await axios.get(
          "https://bracket-buddy.onrender.com/stages"
        );
        if (res.data) {
          setStages(res.data.all_stages);
        } else {
          console.error("Invalid API response:", res);
          setStages([]);
        }
      } catch (error) {
        console.error("Error:", error);
        setStages([]);
      }
    };
    getStages();
  }, []);

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  useEffect(() => {
    if (showStageModal) {
      const nonCounterStages = stages.filter(
        (stage) =>
          !selectedCounterStages.some(
            (counterStage) => counterStage.id === stage.id
          ) && normalizeString(stage.name).includes(normalizeString(searchTerm))
      );
      setFilteredStages(nonCounterStages);
    } else if (showCounterStageModal) {
      const possibleCounterStages = stages.filter(
        (stage) =>
          !selectedStarterStages.some(
            (starterStage) => starterStage.id === stage.id
          ) && normalizeString(stage.name).includes(normalizeString(searchTerm))
      );
      setFilteredStages(possibleCounterStages);
    } else {
      const filtered = stages.filter((stage) =>
        normalizeString(stage.name).includes(normalizeString(searchTerm))
      );
      setFilteredStages(filtered);
    }
  }, [
    searchTerm,
    stages,
    showStageModal,
    showCounterStageModal,
    selectedStarterStages,
    selectedCounterStages,
  ]);

  useEffect(() => {
    function handleClickOutside(e) {
      if (stageModalRef.current && !stageModalRef.current.contains(e.target)) {
        setShowStageModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [stageModalRef]);

  useEffect(() => {
    function handleClickOutsideCounter(e) {
      if (
        counterStageModalRef.current &&
        !counterStageModalRef.current.contains(e.target)
      ) {
        setShowCounterStageModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutsideCounter);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideCounter);
  }, [counterStageModalRef]);

  useEffect(() => {
    onStarterStageChange(selectedStarterStages);
  }, [selectedStarterStages]);

  useEffect(() => {
    onCounterStageChange(selectedCounterStages);
  }, [selectedCounterStages]);

  const toggleModalStageSelection = (stage) => {
    if (selectedModalStages.some((s) => s.id === stage.id)) {
      setSelectedModalStages(
        selectedModalStages.filter((s) => s.id !== stage.id)
      );
    } else {
      setSelectedModalStages([...selectedModalStages, stage]);
    }
  };
  const toggleModalCounterStageSelection = (stage) => {
    if (selectedModalCounterStages.some((s) => s.id === stage.id)) {
      setSelectedModalCounterStages(
        selectedModalCounterStages.filter((s) => s.id !== stage.id)
      );
    } else {
      setSelectedModalCounterStages([...selectedModalCounterStages, stage]);
    }
  };

  const addSelectedStages = () => {
    setSelectedStarterStages(selectedModalStages);
    setShowStageModal(false);
    setSearchTerm("");
  };

  const removeStage = (id) => {
    setSelectedStarterStages((prev) => prev.filter((stage) => stage.id !== id));
    setSelectedModalStages((prev) => prev.filter((stage) => stage.id !== id));
  };

  const addSelectedCounterStages = () => {
    setSelectedCounterStages(selectedModalCounterStages);
    setShowCounterStageModal(false);
    setSearchTerm("");
  };

  const removeCounterStage = (id) => {
    setSelectedCounterStages((prev) => prev.filter((stage) => stage.id !== id));
    setSelectedModalCounterStages((prev) =>
      prev.filter((stage) => stage.id !== id)
    );
  };

  const openStageModal = () => {
    setSelectedModalStages([...selectedStarterStages]);
    setShowStageModal(true);
  };

  const closeStageModal = () => {
    setShowStageModal(false);
    setSearchTerm("");
  };

  const openCounterStageModal = () => {
    setSelectedModalCounterStages([...selectedCounterStages]);
    setShowCounterStageModal(true);
  };

  const closeCounterStageModal = () => {
    setShowCounterStageModal(false);
    setSearchTerm("");
  };

  useEffect(() => {
    const numStarterStages = selectedStarterStages.length;
    setNumBansPlayer1(Math.max(0, numStarterStages - 1));
    setNumBansPlayer2(Math.max(0, numStarterStages - player1Bans - 1));

    if (player1Bans.length > numBansPlayer1) {
      setPlayer1Bans(0);
    }
  }, [selectedStarterStages, player1Bans]);

  useEffect(() => {
    const numStarterStages = selectedStarterStages.length;
    const numCounterStages = selectedCounterStages.length;
    setNumBansWinner(Math.max(0, numStarterStages + numCounterStages - 1));

    if (winnerBans.length > numBansWinner) {
      setWinnerBans(0);
    }
  }, [selectedStarterStages, player1Bans]);

  const player1BanOptions = () => {
    let options = [];
    for (let i = 0; i <= numBansPlayer1; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };
  const player2BanOptions = () => {
    let options = [];
    for (let i = 0; i <= numBansPlayer2; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const winnerBanOptions = () => {
    let options = [];
    for (let i = 0; i <= numBansWinner; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const handlePlayer1Bans = (e) => {
    const numBans = parseInt(e.target.value);
    setPlayer1Bans(numBans);
    setFormData({
      ...formData,
      game1NumBans: {
        ...formData.game1NumBans,
        player1Num: numBans,
      },
    });
  };

  const handlePlayer2Bans = (e) => {
    const numBans = parseInt(e.target.value);
    setFormData({
      ...formData,
      game1NumBans: {
        ...formData.game1NumBans,
        player2Num: numBans,
      },
    });
  };

  const handleWinnerBans = (e) => {
    const numBans = parseInt(e.target.value);
    setFormData({
      ...formData,
      nextGameBans: numBans,
    });
  };

  const currPasscode = `${digit1}${digit2}${digit3}${digit4}`;

  const handleBackspaceFocusPrevious = (e, currentRef, previousRef) => {
    if (e.key === "Backspace" && e.target.value === "") {
      e.preventDefault();
      if (previousRef) {
        previousRef.current.focus();
      }
    }
  };

  const handleInputChange = (e, setter, nextRef) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      setter(value);
      if (value && nextRef) {
        nextRef.current.focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const combinedPasscode = `${digit1}${digit2}${digit3}${digit4}`;

    if (selectedStarterStages.length < 1) {
      setStarterStagesError("Please select one or more starter stages.");
      return;
    }

    if (combinedPasscode.length < 4) {
      setPasscodeError("Please enter a  4-digit passcode.");
      return;
    }

    const completeForm = {
      ...formData,
      passcode: combinedPasscode,
    };

    try {
      const res = await axios.post(
        "https://bracket-buddy.onrender.com/create-room",
        completeForm
      );
      const roomCode = res.data.room_code;
      localStorage.setItem("access_token", res.data.access_token);
      navigate(`/rooms/${roomCode}`);
    } catch (error) {
      console.error("Error creating room:", error);
      return <div>Error creating room.</div>;
    }
  };

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwtDecode(access_token);
      if (decodedToken.sub) {
        setIsLogged(true);
        setCreatorGamertag(decodedToken.gamertag);
      } else {
        setIsLogged(false);
      }
    } else {
      setIsLogged(false);
    }
  }, [access_token]);

  return (
    <>
      {!isLoading ? (
        <form className="selection-form-each" onSubmit={handleSubmit}>
          <div className="selection-form-field">
            <div className="selection-form-title">Room Creator</div>
            <input
              type="text"
              name="roomCreator"
              placeholder="Enter Your Gamertag"
              onChange={handleChange}
              className="form-control room-creator"
              required
            />
          </div>
          <div className="selection-form-field">
            <div className="selection-form-title">Set Type</div>
            <div className="custom-control custom-radio selection-form-choices">
              <label className="selection-form-each-input-label">
                <input
                  type="radio"
                  name="setType"
                  value="3"
                  checked={currSetType === "3"}
                  onChange={handleChange}
                  className="custom-control-input"
                  required
                />
                Best of 3
              </label>
            </div>
            <div className="custom-control custom-radio selection-form-choices">
              <label className="selection-form-each-input-label">
                <input
                  type="radio"
                  name="setType"
                  value="5"
                  checked={currSetType === "5"}
                  onChange={handleChange}
                  className="custom-control-input"
                  required
                />
                Best of 5
              </label>
            </div>
          </div>

          <div className="selection-form-field">
            <div className="selection-form-title">Selection Order</div>
            <div className="custom-control custom-radio selection-form-choices">
              <label className="selection-form-each-input-label">
                <input
                  type="radio"
                  name="selectionOrder"
                  value="characters"
                  checked={currSelectionOrder === "characters"}
                  onChange={handleChange}
                  className="custom-control-input"
                  required
                />
                Characters First
              </label>
            </div>
            <div className="custom-control custom-radio selection-form-choices">
              <label className="selection-form-each-input-label">
                <input
                  type="radio"
                  name="selectionOrder"
                  value="stage"
                  checked={currSelectionOrder === "stage"}
                  onChange={handleChange}
                  className="custom-control-input"
                  required
                />
                Stage First
              </label>
            </div>
          </div>
          <div className="selection-form-field">
            <div className="selection-form-title">Starter Stages</div>
            {starterStagesError && (
              <div className="error-message">{starterStagesError}</div>
            )}
          </div>
          <div>
            <div className="starter-stages-container">
              {selectedStarterStages.map((stage) => (
                <div
                  key={stage.id}
                  className="card card-stage starter-stage-card"
                >
                  <img
                    src={stage.image}
                    alt={stage.name}
                    className="card-stage-img-top"
                  />
                  <div className="card-body p-1">
                    <p className="card-text card-stage-text">{stage.name}</p>
                  </div>
                  <button
                    onClick={() => removeStage(stage.id)}
                    className="card-remove-btn"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              ))}
            </div>
            <div className="select-stage-button-container">
              <button
                type="button"
                className="btn btn-primary select-stage-button"
                onClick={openStageModal}
              >
                Select Stages
              </button>
            </div>
          </div>

          <div
            className={
              showStageModal ? "modal fade show d-block" : "modal fade"
            }
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: showStageModal ? "rgba(0,0,0,0.5)" : "" }}
          >
            <div className="modal-dialog" role="document" ref={stageModalRef}>
              <div className="modal-content">
                <div className="modal-header modal-header-title">
                  <div className="modal-title modal-header-stages">
                    Select Starter Stages
                  </div>
                  <div className="input-group modal-search-container">
                    <input
                      type="text"
                      placeholder="Search stage..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control search-input"
                    />
                    <button
                      type="button"
                      onClick={() => setSearchTerm("")}
                      className="btn bg-transparent stage-search-clear"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="modal-body selection-assistant-stages">
                  {stages.length > 0 ? (
                    filteredStages.map((stage) => (
                      <div
                        key={stage.id}
                        className={`card starter-stage-card ${
                          selectedModalStages.find((s) => s.id === stage.id)
                            ? "card-stage-selected"
                            : ""
                        }`}
                        onClick={() => toggleModalStageSelection(stage)}
                      >
                        <img
                          className="card-stage-img-top"
                          src={stage.image}
                          alt={stage.name}
                        />
                        <div className="card-body p-1">
                          <p className="card-text card-stage-text">
                            {stage.name}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: "50vh",
                        left: "50vw",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
                <div className="modal-footer modal-footer-stages">
                  <button
                    type="button"
                    className="btn btn-outline-primary add-stages-button"
                    onClick={addSelectedStages}
                  >
                    {selectedStarterStages.length > 0 ? "Save" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary add-stages-button"
                    onClick={closeStageModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* COUNTERPICK STAGE SELECTION */}
          <div className="selection-form-field">
            <div className="selection-form-title">Counterpick Stages</div>
          </div>
          <div>
            <div className="counter-stages-container">
              {selectedCounterStages.map((stage) => (
                <div
                  key={stage.id}
                  className="card card-stage counter-stage-card"
                >
                  <img
                    src={stage.image}
                    alt={stage.name}
                    className="card-stage-img-top"
                  />
                  <div className="card-body p-1">
                    <p className="card-text card-stage-text">{stage.name}</p>
                  </div>
                  <button
                    onClick={() => removeCounterStage(stage.id)}
                    className="card-remove-btn"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              ))}
            </div>
            <div className="select-stage-button-container">
              <button
                type="button"
                className="btn btn-primary select-stage-button"
                onClick={openCounterStageModal}
              >
                Select Stages
              </button>
            </div>
          </div>

          <div
            className={
              showCounterStageModal ? "modal fade show d-block" : "modal fade"
            }
            tabIndex="-1"
            role="dialog"
            style={{
              backgroundColor: showCounterStageModal ? "rgba(0,0,0,0.5)" : "",
            }}
          >
            <div
              className="modal-dialog"
              role="document"
              ref={counterStageModalRef}
            >
              <div className="modal-content">
                <div className="modal-header modal-header-title">
                  <div className="modal-title modal-header-stages">
                    Select Counterpick Stages
                  </div>
                  <div className="input-group modal-search-container">
                    <input
                      type="text"
                      placeholder="Search stage..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control selection-assistant-search-input search-input"
                    />
                    <button
                      type="button"
                      onClick={() => setSearchTerm("")}
                      className="btn bg-transparent stage-search-clear"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div className="modal-body selection-assistant-stages">
                  {stages.length > 0 ? (
                    filteredStages.map((stage) => (
                      <div
                        key={stage.id}
                        className={`card counter-stage-card ${
                          selectedModalCounterStages.find(
                            (s) => s.id === stage.id
                          )
                            ? "card-stage-selected"
                            : ""
                        }`}
                        onClick={() => toggleModalCounterStageSelection(stage)}
                      >
                        <img
                          className="card-stage-img-top"
                          src={stage.image}
                          alt={stage.name}
                        />
                        <div className="card-body p-1">
                          <p className="card-text card-stage-text">
                            {stage.name}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: "50vh",
                        left: "50vw",
                      }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
                <div className="modal-footer modal-footer-stages">
                  <button
                    type="button"
                    className="btn btn-outline-primary add-stages-button"
                    onClick={addSelectedCounterStages}
                  >
                    {selectedCounterStages.length > 0 ? "Save" : "Add"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary add-stages-button"
                    onClick={closeCounterStageModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="selection-form-field">
            <div className="selection-form-title">Stage Clause</div>
            <div className="custom-control custom-radio selection-form-choices">
              <label className="selection-form-each-input-label">
                <input
                  type="radio"
                  name="stageClause"
                  value="dsr"
                  checked={currStageClause === "dsr"}
                  onChange={handleChange}
                  className="custom-control-input"
                  required
                />
                DSR
                <span className="question-circle-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="question-circle"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                  </svg>
                  <div className="tooltip-message">
                    <p className="dsr-msg-title">Dave's Stupid Rule</p>
                    <p className="dsr-msg-info">
                      The player choosing the game stage cannot select any stage
                      that said player has previously won on.
                    </p>
                  </div>
                </span>
              </label>
            </div>
            <div className="custom-control custom-radio selection-form-choices">
              <label className="selection-form-each-input-label">
                <input
                  type="radio"
                  name="stageClause"
                  value="mdsr"
                  checked={currStageClause === "mdsr"}
                  onChange={handleChange}
                  className="custom-control-input"
                  required
                />
                MDSR
                <span className="question-circle-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="question-circle"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                  </svg>
                  <div className="tooltip-message">
                    <p className="dsr-msg-title">Modified Dave's Stupid Rule</p>
                    <p className="dsr-msg-info">
                      The player choosing the game stage cannot select the last
                      stage that said player won on.
                    </p>
                  </div>
                </span>
              </label>
            </div>
            <div className="custom-control custom-radio selection-form-choices">
              <label className="selection-form-each-input-label">
                <input
                  type="radio"
                  name="stageClause"
                  value="nodsr"
                  checked={currStageClause === "nodsr"}
                  onChange={handleChange}
                  className="custom-control-input"
                  required
                />
                No DSR/MDSR
              </label>
            </div>
          </div>
          <div className="selection-form-field">
            <div className="selection-form-title">Game 1 Stage Bans</div>
            <div className="row align-items-center">
              <div className="col-auto">
                <div
                  className={`stage-ban-num ${
                    selectedStarterStages.length === 0
                      ? "stage-selection-off"
                      : ""
                  }`}
                >
                  Player 1 Bans
                </div>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <select
                    className="form-control selection-assistant-stage-bans"
                    name="player1Bans"
                    disabled={selectedStarterStages.length === 0}
                    onChange={handlePlayer1Bans}
                  >
                    {player1BanOptions()}
                  </select>
                </div>
              </div>
              <div className="col-auto">
                <div className="stage-ban-num">Stage(s),</div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-auto">
                <div
                  className={`stage-ban-num ${
                    selectedStarterStages.length === 0
                      ? "stage-selection-off"
                      : ""
                  }`}
                >
                  Player 2 Bans
                </div>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <select
                    className="form-control selection-assistant-stage-bans"
                    name="player2Bans"
                    onChange={handlePlayer2Bans}
                    disabled={
                      selectedStarterStages.length === 0 || player1Bans === 0
                    }
                  >
                    {player2BanOptions()}
                  </select>
                </div>
              </div>
              <div className="col-auto">
                <div className="stage-ban-num">Stage(s),</div>
              </div>
            </div>
            <div className="stage-ban-num stage-choice">
              Player 1 chooses from the remaining starter stages
            </div>
            {starterStagesError && (
              <div className="error-message">{starterStagesError}</div>
            )}
          </div>
          <div className="selection-form-field">
            <div className="selection-form-title">Game 2+ Stage Bans</div>
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="stage-ban-num">Winner Bans</div>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <select
                    className="form-control selection-assistant-stage-bans"
                    name="winnerBans"
                    onChange={handleWinnerBans}
                    disabled={selectedCounterStages.length === 0}
                  >
                    {winnerBanOptions()}
                  </select>
                </div>
              </div>
              <div className="col-auto">
                <div className="stage-ban-num">Stage(s),</div>
              </div>
            </div>
            <div className="stage-ban-num stage-choice">
              Loser chooses from the remaining stages
            </div>
          </div>
          <div className="selection-form-field">
            <div className="selection-form-title">Room Passcode</div>
            <input
              ref={digit1Ref}
              type="tel"
              maxLength="1"
              value={digit1}
              onChange={(e) => handleInputChange(e, setDigit1, digit2Ref)}
              onKeyDown={(e) =>
                handleBackspaceFocusPrevious(e, digit1Ref, null)
              }
              className="passcode-input"
              inputMode="numeric"
            />
            <input
              ref={digit2Ref}
              type="tel"
              maxLength="1"
              value={digit2}
              onChange={(e) => handleInputChange(e, setDigit2, digit3Ref)}
              onKeyDown={(e) =>
                handleBackspaceFocusPrevious(e, digit2Ref, digit1Ref)
              }
              className="passcode-input"
              inputMode="numeric"
            />
            <input
              ref={digit3Ref}
              type="tel"
              maxLength="1"
              value={digit3}
              onChange={(e) => handleInputChange(e, setDigit3, digit4Ref)}
              onKeyDown={(e) =>
                handleBackspaceFocusPrevious(e, digit3Ref, digit2Ref)
              }
              className="passcode-input"
              inputMode="numeric"
            />
            <input
              ref={digit4Ref}
              type="tel"
              maxLength="1"
              value={digit4}
              onChange={(e) => handleInputChange(e, setDigit4, null)}
              onKeyDown={(e) =>
                handleBackspaceFocusPrevious(e, digit4Ref, digit3Ref)
              }
              className="passcode-input"
              inputMode="numeric"
            />
            {starterStagesError && (
              <div className="error-message">{starterStagesError}</div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-outline-primary create-room-button"
            onClick={handleSubmit}
          >
            Create Room
          </button>
        </form>
      ) : (
        <div
          className="spinner-border text-primary"
          role="status"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50vh",
            left: "50vw",
          }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </>
  );
};

export default SelectionForm;
