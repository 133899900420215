import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import "./Room.css";
import { useNavigate } from "react-router-dom";
import RPS from "./RPS";
import CharacterSelect from "./CharacterSelect";
import StageSelect from "./StageSelect";
import GameDetails from "./GameDetails";
import GameHistory from "./GameHistory";

const Room = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [matchRequested, setMatchRequested] = useState(false);
  const [matchStarted, setMatchStarted] = useState(false);
  const [gamertag, setGamertag] = useState("");
  const [oppGamertag, setOppGamertag] = useState("");
  const [passcode, setPasscode] = useState("");
  const [activeTab, setActiveTab] = useState("match");
  const [unreadMsgs, setUnreadMsgs] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [rejectMsg, setRejectMsg] = useState("");
  const [notification, setNotification] = useState("");
  const [isMatched, setIsMatched] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [rules, setRules] = useState(null);
  const [loadingRules, setLoadingRules] = useState(true);
  const [matchStep, setMatchStep] = useState(1);
  const [maxMatchSteps, setMaxMatchSteps] = useState(8);
  const [rpsWinner, setRPSWinner] = useState(null);
  const [rpsLoser, setRPSLoser] = useState(null);
  const [roomAccess, setRoomAccess] = useState(null);
  const [selectedRPS, setSelectedRPS] = useState(null);
  const [showRPS, setShowRPS] = useState(false);
  const [selectedChar, setSelectedChar] = useState(null);
  const [showChar, setShowChar] = useState(false);
  const [forPlayer, setForPlayer] = useState(null);
  const [otherPlayer, setOtherPlayer] = useState(null);
  const [stageBans, setStageBans] = useState({});
  const [gameStage, setGameStage] = useState(null);
  const [gameWinner, setGameWinner] = useState(null);
  const [gameLoser, setGameLoser] = useState(null);
  const [showWinner, setShowWinner] = useState(false);
  const [matchEnd, setMatchEnd] = useState(false);
  const [gameNo, setGameNo] = useState(1);
  const [numWins, setNumWins] = useState(0);
  const [numWinsOpp, setNumWinsOpp] = useState(0);
  const [stageBanTurn, setStageBanTurn] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [flip, setFlip] = useState(false);
  const [hideCard, setHideCard] = useState(true);
  const [turnPlayerBanLimit, setTurnPlayerBanLimit] = useState(0);
  const banLimitRef = useRef(turnPlayerBanLimit);

  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const index = path.indexOf("/rooms/");
  const roomcode = path.substring(index + "/rooms/".length);
  const socketRef = useRef(null);
  let firstPlayer = 0;
  let secondPlayer = 0;

  const handleTabSelect = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "chat") {
      setUnreadMsgs(false);
    }
  };

  if (forPlayer && otherPlayer) {
    firstPlayer = forPlayer.gamertag === gamertag ? forPlayer : otherPlayer;
    secondPlayer = forPlayer.gamertag === gamertag ? otherPlayer : forPlayer;
  }

  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    banLimitRef.current = turnPlayerBanLimit;
  }, [turnPlayerBanLimit, rpsWinner, stageBans, gameWinner]);

  useEffect(() => {
    if (access_token) {
      const decodedToken = jwtDecode(access_token);
      setRoomAccess(decodedToken.room_code);
    } else {
      setRoomAccess(null);
    }
  }, [access_token]);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      setGamertag(decodedToken.gamertag);
      setPasscode(decodedToken.passcode);
      setIsCreator(decodedToken.isCreator);
    }

    socketRef.current = io.connect("https://bracket-buddy.onrender.com", {
      transports: ["websocketRef.current", "polling"],
      // reconnectionAttempts: 5,
      // reconnectionDelay: 1000,
      withCredentials: true,
      timeout: 20000,
    });

    socketRef.current.on("connect_error", (err) => {
      console.error("socketRef.current connection error: ", err);
    });

    socketRef.current.emit("join_room", {
      roomcode: roomcode,
      passcode: passcode,
      gamertag: gamertag,
    });

    socketRef.current.on("update_users", (updatedUsers) => {
      setUsers(updatedUsers);
    });

    socketRef.current.on("receive_match_request", () => {
      setMatchRequested(true);
    });

    socketRef.current.on("match_accepted", (data) => {
      const { opp_gamertag } = data;
      if (opp_gamertag !== gamertag && !isCreator) {
        setNotification("You were not selected for the match. Redirecting...");
        setTimeout(() => {
          navigate(`/selection-assistant`);
        }, 2500);
      } else if (opp_gamertag === gamertag || isCreator) {
        setIsMatched(true);
      }
      setOppGamertag(opp_gamertag);
      setMatchStarted(true);
    });

    socketRef.current.on("match_declined", (data) => {
      setMatchRequested(false);
      setSelectedUser(null);
      setRejectMsg(`${data["opp_gamertag"]} has rejected your match request`);
    });

    socketRef.current.on("receive_message", (data) => {
      setChatMessages((prevMessages) => [...prevMessages, data]);
      if (activeTab !== "Chat" && data["sender"] !== gamertag) {
        setUnreadMsgs(true);
      } else {
        setUnreadMsgs(false);
      }
    });

    socketRef.current.on("show_rps_results", (data) => {
      const rpsResult = data["result"];
      const forPlayerTag = data["for_player"];
      const forPlayerOption = data["for_player_option"];
      const otherPlayerTag = data["other_player"];
      const otherPlayerOption = data["other_player_option"];
      if (rpsResult === "Tie") {
        setForPlayer((prevData) => {
          return {
            ...prevData,
            gamertag: forPlayerTag,
            option: forPlayerOption,
          };
        });
        setOtherPlayer((prevData) => {
          return {
            ...prevData,
            gamertag: otherPlayerTag,
            option: otherPlayerOption,
          };
        });
        setShowRPS(true);
        setTimeout(() => {
          setSelectedRPS(null);
          setShowRPS(false);
          setHideCard(true);
          setForPlayer(null);
          setOtherPlayer(null);
        }, 2500);
      } else if (rpsResult === "Win") {
        setRPSWinner(forPlayerTag);
        setRPSLoser(otherPlayerTag);
        setShowRPS(true);
        setForPlayer((prevData) => {
          return {
            ...prevData,
            gamertag: forPlayerTag,
            option: forPlayerOption,
          };
        });
        setOtherPlayer((prevData) => {
          return {
            ...prevData,
            gamertag: otherPlayerTag,
            option: otherPlayerOption,
          };
        });
        if (banLimitRef.current > 0) {
          setStageBanTurn(forPlayerTag);
        }
      } else {
        setRPSWinner(otherPlayerTag);
        setRPSLoser(forPlayerTag);
        setShowRPS(true);
        setForPlayer((prevData) => {
          return {
            ...prevData,
            gamertag: forPlayerTag,
            option: forPlayerOption,
          };
        });
        setOtherPlayer((prevData) => {
          return {
            ...prevData,
            gamertag: otherPlayerTag,
            option: otherPlayerOption,
          };
        });
        if (banLimitRef.current > 0) {
          setStageBanTurn(otherPlayerTag);
        }
      }
    });
    socketRef.current.on("show_chars", (data) => {
      const forPlayerTag = data["for_player"];
      const forPlayerChar = data["for_player_char"];
      const otherPlayerTag = data["other_player"];
      const otherPlayerChar = data["other_player_char"];
      const gameKey = data["game_key"];
      setForPlayer((prevData) => {
        return {
          ...prevData,
          gamertag: forPlayerTag,
          [`character_${gameKey}`]: forPlayerChar,
        };
      });
      setOtherPlayer((prevData) => {
        return {
          ...prevData,
          gamertag: otherPlayerTag,
          [`character_${gameKey}`]: otherPlayerChar,
        };
      });
      setShowChar(true);
    });
    socketRef.current.on("show_bans", (data) => {
      const newBans = data["newBans"];
      const banBy = data["banBy"];
      const rpsWinner = data["rpsWinner"];
      const rpsLoser = data["rpsLoser"];
      const gameNo = data["gameNo"];
      setStageBans((prevStageBans) => ({
        ...prevStageBans,
        [banBy]: {
          ...prevStageBans[banBy],
          [`game${gameNo}`]: newBans,
        },
      }));
      setStageBanTurn((prevPlayer) => {
        if (!prevPlayer && banLimitRef.current > 0) {
          return rpsWinner;
        }
        return banLimitRef.current > 0
          ? prevPlayer === rpsWinner
            ? rpsLoser
            : rpsWinner
          : null;
      });
    });

    socketRef.current.on("show_stage", (data) => {
      const gameNo = data["gameNo"];
      setGameStage((prevData) => {
        return { ...prevData, [`game${gameNo}`]: data["gameStage"] };
      });
    });

    socketRef.current.on("confirm_winner", (data) => {
      const winner = data["winner"];
      const loser = data["loser"];
      const matchComplete = data["matchComplete"];
      const gameNo = data["gameNo"];
      setShowChar(false);

      if (winner) {
        if (winner === gamertag) {
          setNumWins((currWins) => currWins + 1);
        } else {
          setNumWinsOpp((currWins) => currWins + 1);
        }
      }

      setMatchEnd(matchComplete);

      if (winner) {
        setGameWinner((prevData) => {
          return { ...prevData, [`game${gameNo}`]: winner };
        });
        setGameLoser((prevData) => {
          return { ...prevData, [`game${gameNo}`]: loser };
        });
        setShowWinner(true);
        setStageBanTurn(winner);
      } else {
        setShowWinner(true);
        setTimeout(() => {
          setShowWinner(false);
        }, 2500);
      }
    });

    socketRef.current.on("next_step", () => {
      if (matchStep < maxMatchSteps) {
        setMatchStep((prevStep) => prevStep + 1);
      }
    });

    return () => {
      if (socketRef.current.readyState === 1) {
        socketRef.current.off("user_joined");
        socketRef.current.off("user_left");
        socketRef.current.off("update_users");
        socketRef.current.off("receive_match_request");
        socketRef.current.off("match_started");
        socketRef.current.off("match_declined");
        socketRef.current.off("match_accepted");
        socketRef.current.off("receive_message");
        socketRef.current.off("show_results");
        socketRef.current.off("show_chars");
        socketRef.current.off("show_bans");
        socketRef.current.off("show_stage");
        socketRef.current.off("confirm_winner");
        socketRef.current.off("next_step");
        socketRef.current.close();
        socketRef.current.disconnect();
      }
    };
  }, [users, gamertag, roomcode, passcode]);
  useEffect(() => {
    const getRules = async () => {
      try {
        const res = await axios.post(
          "https://bracket-buddy.onrender.com/get_rules",
          {
            roomcode,
          }
        );
        setRules(res.data);
        setLoadingRules(false);
      } catch (error) {
        console.error("Error fetching rules:", error);
      }
      setLoadingRules(false);
    };
    if (roomcode) {
      getRules();
    }

    if (rules) {
      const maxSteps = (rules.rules.setType - 1) * 2 + 4;
      setMaxMatchSteps(maxSteps);
    }
  }, [roomcode]);

  useEffect(() => {
    if (rules) {
      const banLimit =
        gameNo > 1
          ? rules.rules.nextGameBans
          : Object.keys(stageBans).length !== 0
          ? rules.rules.game1NumBans.player2Num
          : rules.rules.game1NumBans.player1Num;
      setTurnPlayerBanLimit(banLimit);
    }
  }, [rpsWinner, rpsLoser, rules, gameWinner, stageBans, gameNo]);

  const moveToNextStep = () => {
    if (matchStep < maxMatchSteps) {
      setMatchStep((prevStep) => prevStep + 1);
    }
  };

  const creatorGamertag =
    users.find((user) => user.isCreator)?.gamertag || "Loading...";

  const handleParticipantSelect = (user) => {
    if (!user.isCreator) {
      setSelectedUser(selectedUser === user.gamertag ? null : user.gamertag);
      setRejectMsg("");
    }
  };

  const handleRequestMatch = () => {
    if (selectedUser) {
      socketRef.current.emit("request_match", {
        room_code: roomcode,
        opp_gamertag: selectedUser,
        gamertag: creatorGamertag,
      });
      setMatchRequested(true);
      setRejectMsg("");
    }
  };

  const handleAcceptMatch = () => {
    socketRef.current.emit("accept_match", {
      room_code: roomcode,
      opp_gamertag: gamertag,
      creatorGamertag: creatorGamertag,
    });
    setMatchRequested(false);
  };

  const handleRejectMatch = () => {
    socketRef.current.emit("reject_match", {
      room_code: roomcode,
      opp_gamertag: gamertag,
    });
    setRejectMsg(`${selectedUser} has rejected your match request`);
  };

  const handleSendMessage = () => {
    if (isMatched) {
      if (messageInput.trim() !== "") {
        socketRef.current.emit("send_message", {
          room_code: roomcode,
          message: messageInput.trim(),
          sender: gamertag,
        });
        setMessageInput("");
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleRPSSelect = (option) => {
    if (isMatched) {
      setSelectedRPS(option);
      setHideCard(false);
      socketRef.current.emit("send_rps", {
        room_code: roomcode,
        option: option,
        gamertag: gamertag,
      });
    }
  };

  const handleCharSelect = (char) => {
    if (isMatched) {
      setSelectedChar(char);
    }
  };

  const handleCharConfirm = () => {
    if (selectedChar) {
      socketRef.current.emit("send_char", {
        room_code: roomcode,
        character: selectedChar,
        gamertag: gamertag,
        game_num: gameNo,
      });
    }
  };

  const handleStageBan = (newBans) => {
    if (newBans) {
      socketRef.current.emit("ban_stages", {
        room_code: roomcode,
        newBans: newBans,
        gamertag: gamertag,
        gameNo: gameNo,
      });
    }
  };

  const handleStageSelect = (stage) => {
    socketRef.current.emit("select_stage", {
      room_code: roomcode,
      stage: stage,
      gamertag: gamertag,
      game_num: gameNo,
    });
  };

  const handleNextStep = () => {
    socketRef.current.emit("next_step_ready", {
      room_code: roomcode,
      gamertag: gamertag,
    });
  };

  const handleGameWinner = (option) => {
    if (isMatched) {
      socketRef.current.emit("set_winner", {
        room_code: roomcode,
        option: option,
        gamertag: gamertag,
        best_of: rules.rules.setType,
        game_num: gameNo,
      });
    }
  };

  const showGameDetails = () => {
    socketRef.current.emit("show_game_details", {
      room_code: roomcode,
      rpsWinner: rpsWinner,
    });
  };

  const handleGameEnd = (e) => {
    if (matchEnd) {
      e.preventDefault();
      navigate("/selection-assistant");
    } else {
      setMatchStep(2);
      setShowWinner(false);
      setConfirmed(false);
      setFlip(false);
      setGameNo((prevGameNo) => prevGameNo + 1);
    }
  };

  if (loadingRules) {
    return (
      <div
        className="spinner-border text-primary"
        role="status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: "50vh",
          left: "50vw",
        }}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <>
      {roomAccess === roomcode ? (
        <>
          <h1 className="room-title mt-5">Room ID: {roomcode}</h1>
          <div className="room-details">Host: {creatorGamertag}</div>
          <div className="room-details">Passcode: {passcode}</div>
          <div className="container mt-4">
            <div className="card custom-card">
              <div className="card-body room-info-container">
                {!matchStarted && !notification ? (
                  <>
                    {isCreator ? (
                      <>
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Select Opponent</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.filter((user) => !user.isCreator).length >
                            0 ? (
                              users.map((user, index) =>
                                user.isCreator ? null : (
                                  <tr
                                    key={index}
                                    onClick={() =>
                                      handleParticipantSelect(user)
                                    }
                                    className={
                                      selectedUser === user.gamertag
                                        ? "selected-participant"
                                        : ""
                                    }
                                  >
                                    <td>{user.gamertag}</td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan="1" className="waiting-msg">
                                  Waiting for opponent(s) to join...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <button
                          onClick={handleRequestMatch}
                          disabled={!selectedUser || matchRequested}
                          className="btn btn-outline-primary mt-3"
                        >
                          Request Match
                        </button>
                        {matchRequested ? (
                          <div className="waiting-msg mt-2">
                            Waiting for opponent to accept request...
                          </div>
                        ) : null}
                        {rejectMsg ? (
                          <div className="waiting-msg mt-2">{rejectMsg}</div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <div className="host-name">Players In Lobby:</div>{" "}
                        <ul>
                          {users.map((user, index) => (
                            <li
                              key={index}
                              className={`lobby-participant ${
                                user.isCreator ? "host" : ""
                              }`}
                            >
                              {user.gamertag} {user.isCreator ? "(host)" : ""}
                            </li>
                          ))}
                        </ul>
                        {!matchRequested && (
                          <div className="waiting-msg">
                            Waiting for host to select an opponent...
                          </div>
                        )}
                        {matchRequested && (
                          <div className="waiting-msg">
                            <strong>{creatorGamertag}</strong> has requested a
                            match
                          </div>
                        )}
                        {matchRequested && (
                          <div className="response-buttons">
                            <button
                              className="btn btn-success accept-match"
                              onClick={() => handleAcceptMatch()}
                            >
                              Accept
                            </button>
                            <button
                              className="btn btn-danger decline-match"
                              onClick={() => handleRejectMatch()}
                            >
                              Decline
                            </button>
                          </div>
                        )}
                        {notification && (
                          <div className="notification-msg">{notification}</div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {notification ? (
                      <div className="notification-msg">{notification}</div>
                    ) : (
                      <>
                        <ul className="nav nav-pills custom-tabs mb-3">
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                activeTab === "match" ? "active" : ""
                              }`}
                              onClick={() => handleTabSelect("match")}
                            >
                              Match
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                activeTab === "chat" ? "active" : ""
                              }`}
                              onClick={() => handleTabSelect("chat")}
                            >
                              Chat{" "}
                              <sup>
                                {unreadMsgs ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    fill="red"
                                    class="bi bi-circle-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <circle cx="8" cy="8" r="8" />
                                  </svg>
                                ) : (
                                  ""
                                )}
                              </sup>
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={`nav-link ${
                                activeTab === "rules" ? "active" : ""
                              }`}
                              onClick={() => handleTabSelect("rules")}
                            >
                              Rules
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content">
                          {loadingRules ? (
                            <div>Loading...</div>
                          ) : (
                            <>
                              <div
                                className={`tab-pane fade room-info ${
                                  activeTab === "match" ? "show active" : ""
                                }`}
                              >
                                <div className="game-num mt-5">{`Game ${gameNo}`}</div>
                                <div className="match-score">{`(${numWins} - ${numWinsOpp})`}</div>
                                {matchStep === 1 && (
                                  <RPS
                                    onNextStep={handleNextStep}
                                    onRPSSelect={handleRPSSelect}
                                    opponentGamertag={oppGamertag}
                                    creatorGamertag={creatorGamertag}
                                    forPlayer={forPlayer}
                                    otherPlayer={otherPlayer}
                                    isCreator={isCreator}
                                    rpsWinner={rpsWinner}
                                    selectedRPS={selectedRPS}
                                    showRPS={showRPS}
                                    hideCard={hideCard}
                                    moveToNextStep={moveToNextStep}
                                  />
                                )}
                                {matchStep === 2 ? (
                                  rules["rules"]["selectionOrder"] ===
                                  "characters" ? (
                                    <CharacterSelect
                                      onNextStep={handleNextStep}
                                      selectedChar={selectedChar}
                                      onSelectChar={handleCharSelect}
                                      onCharConfirm={handleCharConfirm}
                                      showChar={showChar}
                                      forPlayer={forPlayer}
                                      otherPlayer={otherPlayer}
                                      isCreator={isCreator}
                                      numWins={numWins}
                                      numWinsOpp={numWinsOpp}
                                      confirmed={confirmed}
                                      setConfirmed={setConfirmed}
                                      flip={flip}
                                      setFlip={setFlip}
                                      moveToNextStep={moveToNextStep}
                                      matchStep={matchStep}
                                    />
                                  ) : (
                                    <div className="stage-select">
                                      <StageSelect
                                        onStageConfirm={showGameDetails}
                                        rules={rules}
                                        rpsWinner={rpsWinner}
                                        gamertag={gamertag}
                                        handleStageBan={handleStageBan}
                                        handleStageSelect={handleStageSelect}
                                        forPlayer={forPlayer}
                                        otherPlayer={otherPlayer}
                                        isCreator={isCreator}
                                        stageBans={stageBans}
                                        rpsLoser={rpsLoser}
                                        gameStage={gameStage}
                                        numWins={numWins}
                                        numWinsOpp={numWinsOpp}
                                        gameNo={gameNo}
                                        gameWinner={gameWinner}
                                        gameLoser={gameLoser}
                                        stageBanTurn={stageBanTurn}
                                        moveToNextStep={moveToNextStep}
                                        matchStep={matchStep}
                                        onNextStep={handleNextStep}
                                        turnPlayerBanLimit={turnPlayerBanLimit}
                                      />
                                    </div>
                                  )
                                ) : null}
                                {matchStep === 3 ? (
                                  rules["rules"]["selectionOrder"] ===
                                  "characters" ? (
                                    <StageSelect
                                      onStageConfirm={showGameDetails}
                                      rules={rules}
                                      rpsWinner={rpsWinner}
                                      gamertag={gamertag}
                                      handleStageBan={handleStageBan}
                                      handleStageSelect={handleStageSelect}
                                      forPlayer={forPlayer}
                                      otherPlayer={otherPlayer}
                                      isCreator={isCreator}
                                      stageBans={stageBans}
                                      rpsLoser={rpsLoser}
                                      gameStage={gameStage}
                                      numWins={numWins}
                                      numWinsOpp={numWinsOpp}
                                      gameNo={gameNo}
                                      gameWinner={gameWinner}
                                      gameLoser={gameLoser}
                                      stageBanTurn={stageBanTurn}
                                      moveToNextStep={moveToNextStep}
                                      matchStep={matchStep}
                                      onNextStep={handleNextStep}
                                      turnPlayerBanLimit={turnPlayerBanLimit}
                                    />
                                  ) : (
                                    <CharacterSelect
                                      onNextStep={handleNextStep}
                                      selectedChar={selectedChar}
                                      onSelectChar={handleCharSelect}
                                      onCharConfirm={handleCharConfirm}
                                      showChar={showChar}
                                      forPlayer={forPlayer}
                                      otherPlayer={otherPlayer}
                                      isCreator={isCreator}
                                      numWins={numWins}
                                      numWinsOpp={numWinsOpp}
                                      confirmed={confirmed}
                                      setConfirmed={setConfirmed}
                                      flip={flip}
                                      setFlip={setFlip}
                                      moveToNextStep={moveToNextStep}
                                      matchStep={matchStep}
                                    />
                                  )
                                ) : null}
                                {matchStep === 4 && (
                                  <GameDetails
                                    gameNo={gameNo}
                                    forPlayer={forPlayer}
                                    otherPlayer={otherPlayer}
                                    gameStage={gameStage}
                                    rules={rules}
                                    handleGameWinner={handleGameWinner}
                                    gameWinner={gameWinner}
                                    showWinner={showWinner}
                                    gamertag={gamertag}
                                    maxMatchSteps={maxMatchSteps}
                                    matchEnd={matchEnd}
                                    handleGameEnd={handleGameEnd}
                                    numWins={numWins}
                                    numWinsOpp={numWinsOpp}
                                  />
                                )}
                              </div>
                            </>
                          )}
                          <div
                            className={`tab-pane fade chat-tab ${
                              activeTab === "chat" ? "show active" : ""
                            }`}
                          >
                            {isMatched && (
                              <div className="chat-container">
                                <div className="chat-messages">
                                  {chatMessages.map((msg, index) => (
                                    <div
                                      key={index}
                                      className={
                                        msg.sender === gamertag
                                          ? "myGamertag chat-message"
                                          : "oppGamertag chat-message"
                                      }
                                    >
                                      {msg.message}
                                    </div>
                                  ))}
                                </div>
                                <div className="message-input-container">
                                  <input
                                    type="text"
                                    placeholder="Type your message..."
                                    value={messageInput}
                                    onChange={(e) =>
                                      setMessageInput(e.target.value)
                                    }
                                    disabled={!isMatched}
                                    onKeyPress={handleKeyPress}
                                    className="message-input"
                                    name="message-input"
                                  />
                                  <label
                                    onClick={handleSendMessage}
                                    disabled={!isMatched}
                                    htmlFor="message-input"
                                    className="send-icon"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-send"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                    </svg>
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className={`tab-pane fade ${
                              activeTab === "rules" ? "show active" : ""
                            }`}
                          >
                            {loadingRules ? (
                              <div>Loading...</div>
                            ) : (
                              <div className="rules-content">
                                <div className="rules-title">Match Ruleset</div>
                                <ul className="ruleset-info-container">
                                  <li className="ruleset-info">
                                    Best of {rules.rules.setType}
                                  </li>
                                  <li className="ruleset-info">
                                    Selection Order:{" "}
                                    {rules.rules.selectionOrder} first
                                  </li>
                                  <li className="ruleset-info">
                                    Stage Clause:{" "}
                                    {rules.rules.stageClause.toUpperCase()}
                                  </li>
                                  <li className="ruleset-info">
                                    Game 1 Stage Bans
                                    <div>
                                      <ul>
                                        <li className="ruleset-info">
                                          Rock, Paper, Scissors
                                        </li>
                                        <li className="ruleset-info">
                                          Winner bans{" "}
                                          {rules.rules.game1NumBans.player1Num}{" "}
                                          stage(s)
                                        </li>
                                        <li className="ruleset-info">
                                          Loser bans{" "}
                                          {rules.rules.game1NumBans.player2Num}{" "}
                                          stage(s)
                                        </li>
                                        <li className="ruleset-info">
                                          Winner chooses from remaining stage(s)
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li className="ruleset-info">
                                    Game 2+ Stage Bans
                                    <div>
                                      <ul>
                                        <li className="ruleset-info">
                                          Winner bans {rules.rules.nextGameBans}{" "}
                                          stage(s)
                                        </li>
                                        <li className="ruleset-info">
                                          Loser chooses from remaining stage(s)
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li className="ruleset-info">
                                    Starter Stages
                                    <div>
                                      <div className="starter-stages-container">
                                        {rules.rules.starterStages.map(
                                          (stage) => (
                                            <div
                                              key={stage.id}
                                              className="card card-stage"
                                            >
                                              <img
                                                src={stage.image}
                                                alt={stage.name}
                                                className="card-stage-img-top"
                                              />
                                              <div className="card-body p-1">
                                                <p className="card-text card-stage-text">
                                                  {stage.name}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="ruleset-info">
                                    Counterpick Stages
                                    <div>
                                      <div className="starter-stages-container">
                                        {rules.rules.counterpickStages.map(
                                          (stage) => (
                                            <div
                                              key={stage.id}
                                              className="card card-stage"
                                            >
                                              <img
                                                src={stage.image}
                                                alt={stage.name}
                                                className="card-stage-img-top"
                                              />
                                              <div className="card-body p-1">
                                                <p className="card-text card-stage-text">
                                                  {stage.name}
                                                </p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h1>No Access to Room {roomcode}</h1>
        </>
      )}
    </>
  );
};

export default Room;
