import React, { useEffect, useState } from "react";
import axios from "axios";
import "./CharacterSelect.css";

const CharacterSelect = ({
  onNextStep,
  selectedChar,
  onSelectChar,
  onCharConfirm,
  showChar,
  forPlayer,
  otherPlayer,
  isCreator,
  numWins,
  numWinsOpp,
  confirmed,
  setConfirmed,
  flip,
  setFlip,
  moveToNextStep,
  matchStep,
}) => {
  const [characters, setCharacters] = useState([]);
  const [hideNext, setHideNext] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState("");
  const gameKey = `game${numWins + numWinsOpp + 1}`;

  //   useEffect(() => {
  //     if (showChar && matchStep === 3) {
  //       setNextStep(true);
  //       setTimeout(() => moveToNextStep(), 3000);
  //     }
  //   }, [showChar, matchStep]);

  useEffect(() => {
    if (showChar) {
      setNextStep(true);
    }
  }, [showChar]);

  useEffect(() => {
    const getCharacters = async () => {
      try {
        const res = await axios.get(
          "https://bracket-buddy.onrender.com/characters"
        );
        if (res.data) {
          setCharacters(res.data.all_characters);
        } else {
          console.error("Invalid API response:", res);
          setCharacters([]);
        }
      } catch (error) {
        console.error("Error:", error);
        setCharacters([]);
      }
    };
    getCharacters();
  }, []);

  useEffect(() => {
    if (showChar && confirmed) {
      setFlip(true);
    } else {
      setFlip(false);
    }
  }, [showChar, confirmed]);

  const handleConfirm = () => {
    onCharConfirm();
    setConfirmed(true);
  };

  const onNextStepClick = () => {
    setHideNext(true);
    setWaitingMessage("Waiting for opponent to confirm...");
    onNextStep();
  };

  return (
    <>
      <h1 className="step-title">Character Selection</h1>
      {characters.length > 0 && confirmed && (
        <div className="character-vs">
          <div
            className={`character-card selected`}
            onClick={() => onSelectChar(selectedChar)}
          >
            <img
              className="character-image"
              src={characters.find((char) => char.id === selectedChar)?.icon}
              alt={characters.find((char) => char.id === selectedChar)?.name}
            />
            <div className="card-body p-1">
              <p className="character-name">
                {isCreator ? forPlayer.gamertag : otherPlayer.gamertag}
              </p>
            </div>
          </div>
          <div className="vs-container">
            <div className="vs">vs</div>
          </div>
          <div className={`character-card ${flip ? "flipped" : ""}`}>
            {flip ? (
              isCreator ? (
                otherPlayer[`character_${gameKey}`]?.id ? (
                  <img
                    className="character-image"
                    src={
                      characters.find(
                        (char) =>
                          char.id === otherPlayer[`character_${gameKey}`]?.id
                      )?.icon
                    }
                    alt={
                      characters.find(
                        (char) =>
                          char.id === otherPlayer[`character_${gameKey}`]?.id
                      )?.name
                    }
                  />
                ) : (
                  <img
                    className="character-image"
                    src="https://images.start.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png?ehk=uZYU%2B7mwHw%2FmWFuuGYikEQAlMluTTOkjGB%2BAZqznAeM%3D&ehkOptimized=UaTjq2MnZ55hqleRaGyhLgFkMhrpDPLqJ5w5TVb0g7s%3D"
                    alt="Unknown Character"
                  />
                )
              ) : forPlayer[`character_${gameKey}`]?.id ? (
                <img
                  className="character-image"
                  src={
                    characters.find(
                      (char) =>
                        char.id === forPlayer[`character_${gameKey}`]?.id
                    )?.icon
                  }
                  alt={
                    characters.find(
                      (char) =>
                        char.id === forPlayer[`character_${gameKey}`]?.id
                    )?.name
                  }
                />
              ) : (
                <img
                  className="character-image"
                  src="https://images.start.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png?ehk=uZYU%2B7mwHw%2FmWFuuGYikEQAlMluTTOkjGB%2BAZqznAeM%3D&ehkOptimized=UaTjq2MnZ55hqleRaGyhLgFkMhrpDPLqJ5w5TVb0g7s%3D"
                  alt="Unknown Character"
                />
              )
            ) : (
              <img
                className="character-image"
                src="https://images.start.gg/images/character/1746/image-485930109cf8a08e1b05d21071a037e9.png?ehk=uZYU%2B7mwHw%2FmWFuuGYikEQAlMluTTOkjGB%2BAZqznAeM%3D&ehkOptimized=UaTjq2MnZ55hqleRaGyhLgFkMhrpDPLqJ5w5TVb0g7s%3D"
                alt="Unknown Character"
              />
            )}

            <div className="card-body p-1">
              <p className="character-name">
                {flip
                  ? isCreator
                    ? otherPlayer.gamertag
                    : forPlayer.gamertag
                  : "Opponent selecting..."}
              </p>
            </div>
          </div>
        </div>
      )}
      {characters.length > 0 ? (
        confirmed ? (
          ""
        ) : (
          <div className="character-container">
            {characters.map((char) => (
              <div
                key={char.id}
                className={`character-card ${
                  selectedChar === char.id ? "selected" : ""
                }`}
                onClick={() => onSelectChar(char.id)}
              >
                <img
                  className="character-image"
                  src={char.icon}
                  alt={char.name}
                />
                <div className="card-body p-1">
                  <p className="character-name">{char.name}</p>
                </div>
              </div>
            ))}
          </div>
        )
      ) : (
        <p>Loading characters...</p>
      )}
      {nextStep && (
        <div className="next-step-msg">
          <p className="next-step-msg">Characters selected!</p>
        </div>
      )}
      {!confirmed && (
        <button
          disabled={!selectedChar}
          onClick={handleConfirm}
          className="btn btn-outline-secondary"
        >
          Confirm
        </button>
      )}
      {flip && !hideNext && (
        <button onClick={onNextStepClick} className="btn btn-outline-secondary">
          Confirm
        </button>
      )}
      {waitingMessage && (
        <div className="waiting-msg">
          <p>{waitingMessage}</p>
        </div>
      )}
    </>
  );
};

export default CharacterSelect;
