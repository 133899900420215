import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import "./SearchResults.css";

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search).get("query");

  useEffect(() => {
    const getSearchResults = async () => {
      try {
        const res = await axios.get(
          `https://bracket-buddy.onrender.com/search`,
          {
            params: {
              query: encodeURIComponent(query),
              page: currPage,
            },
          }
        );
        setResults(res.data.results);
        setTotalPages(res.data.total_pages);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      getSearchResults();
    }
  }, [query, currPage]);

  const handleChangePage = (newPage) => {
    setCurrPage(newPage);
  };

  const getPageNumList = () => {
    const pageNumList = [];
    const maxButtons = 5;

    if (totalPages <= maxButtons) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumList.push(i);
      }
    } else {
      const startPage = Math.max(1, currPage - 2);
      const endPage = Math.min(totalPages, startPage + maxButtons - 1);

      if (startPage > 1) {
        pageNumList.push(1, "...");
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumList.push(i);
      }

      if (endPage < totalPages) {
        pageNumList.push("...", totalPages);
      }
    }

    return pageNumList;
  };

  const handleClick = (playerId) => {
    navigate(`/players/${playerId}`);
  };

  function formatNumbers(num) {
    return num ? num.toLocaleString() : "N/A";
  }

  return (
    <div className="search-results-container">
      <h2 className="search-results-header">Search Results for "{query}"</h2>
      {results && results.length > 0 ? (
        <>
          <div className="player-search">
            {results.map((each, index) => (
              <>
                <div
                  className="card player-search-card"
                  style={{ width: "15rem" }}
                  onClick={() => handleClick(each.id)}
                >
                  {each.image ? (
                    <img
                      src={each.image}
                      alt="Player"
                      className="player-search-img card-img-top"
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/BBL.png"}
                      alt="Player"
                      className="player-search-img card-img-top"
                    />
                  )}
                  <div className="card-body player-search-card-body">
                    <h5 className="card-title player-search-gamertag">
                      <span
                        style={{ color: "#0067da" }}
                        className="player-search-prefix card-title"
                      >
                        {each.prefix ? `${each.prefix} | ` : ""}
                      </span>
                      <b className="player-search-gamertag card-title">
                        {each.gamertag}
                      </b>
                    </h5>
                    <p class="card-text">
                      <div className="search-info-location">
                        <div>{each.city ? `${each.city}` : ""}</div>
                        <div>
                          {each.state ? `${each.state}` : ""}
                          {each.state && each.country ? `, ` : ""}
                          {each.country ? (
                            <>
                              {`${each.country}`}
                              <span
                                className={`fi fi-${each.country.toLowerCase()} country-icon`}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="search-info-elo">{`Elo ${each.elo}`}</div>
                    </p>
                  </div>
                </div>
              </>
            ))}
            {/* <table className="table table-hover">
                            <tbody>
                                {results.map((each, index) => (
                                    <tr key={each.id} onClick={() => handleClick(each.id)} style={{ cursor: 'pointer' }}>
                                        <td className='search-results-name'>
                                            {each.image ? (
                                            <img src={each.image} alt="Player" className='player-search-img' />
                                            ) : (
                                                    <img src={process.env.PUBLIC_URL + '/SSB_logo.png'} alt="Player" className='player-search-img' />
                                                )}
                                            <span 
                                            style={{ color: '#0067da' }}
                                            className='player-search-prefix'
                                            >{each.prefix ? `${each.prefix} | ` : ''}</span>
                                            <b className='player-search-gamertag'>
                                                {each.gamertag}
                                            </b>
                                        </td>
                                        <td>
                                        <div className="search-info-location">
                                                {each.city ? `${each.city}` : ''}
                                                {each.city && each.state ? `, ` : ''}
                                                {each.state ? `${each.state}` : ''}
                                                {(each.city || each.state) && each.country ? `, ` : ''}
                                                {each.country ? (
                                                    <>
                                                        {`${each.country}`}
                                                        <span className={`fi fi-${each.country.toLowerCase()} country-icon`} />
                                                    </>
                                                ) : ''}
                                            </div>
                                        </td>
                                        <td>{formatNumbers(each.elo_rating)}</td>
                                        <td>{Math.round((each.set_wins / each.total_sets) * 100)}%</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
          </div>
          <div className="page-buttons">
            <nav className="page-navigation-container">
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item page-link ${
                    currPage === 1 ? "disabled" : ""
                  }`}
                  onClick={() => handleChangePage(currPage - 1)}
                >
                  {"<"}
                </li>
                {getPageNumList().map((pageNum, index) =>
                  pageNum === "..." ? (
                    <li
                      key={index}
                      className={`page-item page-link ${
                        pageNum === "..." ? "disabled" : ""
                      }`}
                    >
                      ...
                    </li>
                  ) : (
                    <li
                      className={`page-item page-link ${
                        pageNum === currPage ? "active" : ""
                      }`}
                      onClick={() => handleChangePage(pageNum)}
                      key={index}
                    >
                      {formatNumbers(pageNum)}
                    </li>
                  )
                )}
                <li
                  className={`page-item page-link ${
                    currPage === totalPages ? "disabled" : ""
                  }`}
                  onClick={() => handleChangePage(currPage + 1)}
                >
                  {">"}
                </li>
              </ul>
            </nav>
          </div>
        </>
      ) : (
        <p>No results found.</p>
      )}
    </div>
  );
};

export default SearchResults;
