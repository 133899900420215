import React, { useState, useEffect, useRef } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import "./NavBar.css";
import SearchBar from "./SearchBar";
import { jwtDecode } from "jwt-decode";

const NavBar = (props) => {
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(false);
  const navbarCollapseRef = useRef(null);

  const handleLogOut = () => {
    axios.post("https://bracket-buddy.onrender.com/logout");
    props.removeAccessToken();
    navigate("/");
  };

  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    if (access_token) {
      try {
        const decodedToken = jwtDecode(access_token);
        setIsLogged(!!decodedToken.sub);
      } catch (error) {
        console.error("Invalid token", error);
        setIsLogged(false);
      }
    } else {
      setIsLogged(false);
    }
  }, [access_token]);

  const collapseNavbar = () => {
    if (navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove("show");
      const toggler = document.querySelector(".navbar-toggler");
      if (toggler) toggler.classList.add("collapsed");
    }
  };

  const handleClickOutside = (event) => {
    if (
      navbarCollapseRef.current &&
      !navbarCollapseRef.current.contains(event.target) &&
      !document.querySelector(".navbar-toggler").contains(event.target)
    ) {
      collapseNavbar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light navbar-container">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src={process.env.PUBLIC_URL + "/BBL.png"}
              alt="BracketBuddy"
              className="navbar-img"
            />
          </a>

          <div className="d-lg-none mx-auto searchbar-container">
            {" "}
            <SearchBar collapseNavbar={collapseNavbar} />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarNav"
            ref={navbarCollapseRef}
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-itm-container">
              <li className="nav-item">
                <NavLink
                  to="/smash-arena"
                  className="nav-link navbar-itm"
                  onClick={collapseNavbar}
                >
                  Smash Arena
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/mystats"
                  className="nav-link navbar-itm"
                  onClick={collapseNavbar}
                >
                  My Stats
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/player-rankings"
                  className="nav-link navbar-itm"
                  onClick={collapseNavbar}
                >
                  Top 250
                </NavLink>
              </li>
            </ul>
            {isLogged && (
              <div onClick={handleLogOut} className="nav-link navbar-itm">
                Logout
              </div>
            )}
            {!isLogged && (
              <NavLink
                to="/login"
                className="nav-link navbar-itm"
                onClick={collapseNavbar}
              >
                Sign In
              </NavLink>
            )}
            <div className="d-none d-lg-flex ms-auto">
              {" "}
              <SearchBar collapseNavbar={collapseNavbar} />
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default NavBar;
