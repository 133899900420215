import React, { useEffect, useState } from "react";
import "./StageSelect.css";

const StageSelect = ({
  onStageConfirm,
  rules,
  gamertag,
  rpsWinner,
  handleStageBan,
  handleStageSelect,
  stageBans,
  rpsLoser,
  gameNo,
  gameWinner,
  gameLoser,
  stageBanTurn,
  gameStage,
  onNextStep,
  turnPlayerBanLimit,
}) => {
  const [selectedStages, setSelectedStages] = useState([]);
  const [numBans, setNumBans] = useState(0);
  const [stageSelectTurn, setStageSelectTurn] = useState(null);
  const [sameWinners, setSameWinners] = useState(true);
  const [hideNext, setHideNext] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState("");
  const [stageSelected, setStageSelected] = useState(false);
  const onNextStepClick = () => {
    setHideNext(true);
    setWaitingMessage("Waiting for opponent to confirm...");
    onNextStep();
  };
  const gameBanLimit =
    gameNo > 1
      ? rules.rules.nextGameBans
      : rules.rules.game1NumBans.player1Num +
        rules.rules.game1NumBans.player2Num;

  useEffect(() => {
    if (gameNo === 1) {
      setSameWinners(false);
    } else {
      const winners = Object.values(gameWinner);
      setSameWinners(
        winners.every((winner) => winner === winners[0]) ? true : false
      );
    }
  }, [gameWinner]);

  useEffect(() => {
    if (gameStage) {
      if (gameStage[`game${gameNo}`]) {
        setStageSelected(true);
      }
    }
  }, [gameStage]);

  useEffect(() => {
    setSelectedStages([]);
  }, [rpsWinner, gamertag, rpsLoser, rules, gameWinner, stageBans, gameNo]);

  useEffect(() => {
    setNumBans(
      gameNo > 1
        ? (stageBans[gameWinner[`game${gameNo - 1}`]]?.[`game${gameNo}`] || [])
            .length
        : (stageBans[rpsWinner]?.[`game${gameNo}`] || []).length +
            (stageBans[rpsLoser]?.[`game${gameNo}`] || []).length
    );
  }, [stageBans, gameNo, gameWinner, rpsWinner, rpsLoser]);

  useEffect(() => {
    setStageSelectTurn(gameNo > 1 ? gameLoser[`game${gameNo - 1}`] : rpsWinner);
  }, [gameNo, gameLoser, rpsWinner]);

  const toggleStageSelection = (stageId) => {
    if (stageBanTurn === gamertag && numBans < gameBanLimit) {
      setSelectedStages((prevSelected) => {
        if (prevSelected.includes(stageId)) {
          return prevSelected.filter((id) => id !== stageId);
        } else {
          if (selectedStages.length < turnPlayerBanLimit) {
            return [...prevSelected, stageId];
          } else {
            const newSelectedStages = [...prevSelected];
            newSelectedStages.shift();
            return [...newSelectedStages, stageId];
          }
        }
      });
    } else if (stageSelectTurn === gamertag && numBans === gameBanLimit) {
      setSelectedStages((prevSelected) => {
        if (prevSelected.includes(stageId)) {
          return prevSelected.filter((id) => id !== stageId);
        } else {
          if (selectedStages.length === 0) {
            return [...prevSelected, stageId];
          } else {
            const newSelectedStages = [...prevSelected];
            newSelectedStages.shift();
            return [...newSelectedStages, stageId];
          }
        }
      });
    }
    // else if (!stageBanTurn && numBans === gameBanLimit) {

    // }
  };
  const handleConfirm = () => {
    if (numBans < gameBanLimit) {
      handleStageBan(selectedStages);
    } else {
      if (numBans === gameBanLimit) {
        handleStageSelect(selectedStages);
      }
      setSelectedStages([]);
    }
  };

  const latestWin = (winnersObj, gamertag) => {
    const entries = Object.entries(winnersObj);
    let latestGameWin = null;

    for (let i = entries.length - 1; i >= 0; i--) {
      const [key, val] = entries[i];
      if (val === gamertag) {
        latestGameWin = key;
        break;
      }
    }

    return latestGameWin;
  };

  const getAllStageWins = (winnersObj, gamertag) => {
    const entries = Object.entries(winnersObj);
    let allStageWins = [];

    for (let i = entries.length - 1; i >= 0; i--) {
      const [key, val] = entries[i];
      if (val === gamertag) {
        allStageWins.push(key);
      }
    }

    let res = allStageWins.map((stage) => gameStage[stage]);

    if (res.length === 0) {
      res = null;
    }
    return res;
  };

  return (
    <>
      <h1 className="step-title">Stage Selection</h1>
      <div className="stage-container">
        {gameNo > 1 && <div className="stage-type">Starters</div>}
        <div className="starter-stages">
          {rules.rules.starterStages.map((stage) => {
            const MDSRBanned =
              rules.rules.stageClause === "mdsr" && !sameWinners && gameNo > 1
                ? gameStage[latestWin(gameWinner, stageSelectTurn)][0]
                : null;
            const isMDSRBanned = [MDSRBanned].includes(stage.id);
            const isBanned = [
              ...Object.values(stageBans[rpsWinner]?.[`game${gameNo}`] || {}),
              ...Object.values(stageBans[rpsLoser]?.[`game${gameNo}`] || {}),
            ].includes(stage.id);
            const DSRBanned =
              rules.rules.stageClause === "dsr" && gameNo > 1
                ? getAllStageWins(gameWinner, stageSelectTurn)
                : null;
            const isDSRBanned = DSRBanned
              ? DSRBanned.length === 1
                ? DSRBanned[0][0] === stage.id
                : DSRBanned[0][0] === stage.id || DSRBanned[1][0] === stage.id
              : false;
            const isSelected = selectedStages.includes(stage.id);
            const isMaxSelected = numBans === gameBanLimit && isSelected;
            const isGameStage = gameStage
              ? gameStage[`game${gameNo}`]
                ? gameStage[`game${gameNo}`]["0"]
                  ? gameStage[`game${gameNo}`]["0"] === stage.id
                    ? true
                    : false
                  : false
                : false
              : false;
            return (
              <div
                key={stage.id}
                className={`card card-stage ${isSelected ? "selected" : ""} ${
                  isBanned ? "banned" : ""
                } ${isMDSRBanned ? "mdsrbanned" : ""} ${
                  isDSRBanned ? "dsrbanned" : ""
                } ${isMaxSelected ? "max-selected" : ""}
                ${
                  gameStage
                    ? gameStage[`game${gameNo}`]
                      ? gameStage[`game${gameNo}`][0]
                        ? gameStage[`game${gameNo}`][0] === stage.id
                          ? "game-stage"
                          : ""
                        : ""
                      : ""
                    : ""
                }`}
                onClick={() =>
                  isBanned ||
                  isMDSRBanned ||
                  isDSRBanned ||
                  isGameStage ||
                  stageSelected
                    ? null
                    : toggleStageSelection(stage.id)
                }
              >
                <img
                  src={stage.image}
                  alt={stage.name}
                  className="card-stage-img-top"
                />
                {selectedStages.includes(stage.id) &&
                  numBans < gameBanLimit && <div className="overlay">X</div>}
                {isBanned && <div className="overlay stage-banned">Banned</div>}
                {isMDSRBanned && (
                  <div className="overlay stage-banned">Banned (MDSR)</div>
                )}
                {isDSRBanned && (
                  <div className="overlay stage-banned">Banned (DSR)</div>
                )}
                <div className="card-body p-1">
                  <p className="card-text card-stage-text">{stage.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="stage-container">
        {gameNo > 1 && <div className="stage-type">Counters</div>}
        {gameNo > 1 && (
          <div className="counter-stages">
            {rules.rules.counterpickStages.map((stage) => {
              const MDSRBanned =
                rules.rules.stageClause === "mdsr" && !sameWinners && gameNo > 1
                  ? gameStage[latestWin(gameWinner, stageSelectTurn)][0]
                  : null;
              const isMDSRBanned = [MDSRBanned].includes(stage.id);
              const isBanned = [
                ...Object.values(stageBans[rpsWinner]?.[`game${gameNo}`] || {}),
                ...Object.values(stageBans[rpsLoser]?.[`game${gameNo}`] || {}),
              ].includes(stage.id);
              const DSRBanned =
                rules.rules.stageClause === "dsr" && gameNo > 1
                  ? getAllStageWins(gameWinner, stageSelectTurn)
                  : null;
              const isDSRBanned = DSRBanned
                ? DSRBanned[0][0] === stage.id
                : false;

              const isGameStage = gameStage
                ? gameStage[`game${gameNo}`]
                  ? gameStage[`game${gameNo}`]["0"]
                    ? gameStage[`game${gameNo}`]["0"] === stage.id
                      ? true
                      : false
                    : false
                  : false
                : false;
              const isSelected = selectedStages.includes(stage.id);
              const isMaxSelected = numBans === gameBanLimit && isSelected;
              return (
                <div
                  key={stage.id}
                  className={`card card-stage ${isSelected ? "selected" : ""} ${
                    isBanned ? "banned" : ""
                  } ${isMDSRBanned ? "mdsrbanned" : ""} ${
                    isDSRBanned ? "dsrbanned" : ""
                  } ${isMaxSelected ? "max-selected" : ""}`}
                  onClick={() =>
                    isBanned ||
                    isMDSRBanned ||
                    isDSRBanned ||
                    isGameStage ||
                    stageSelected
                      ? null
                      : toggleStageSelection(stage.id)
                  }
                >
                  <img
                    src={stage.image}
                    alt={stage.name}
                    className="card-stage-img-top"
                  />
                  {selectedStages.includes(stage.id) &&
                    numBans < gameBanLimit && <div className="overlay">X</div>}
                  {isBanned && <div className="overlay">Banned</div>}
                  {isMDSRBanned && <div className="overlay">MDSR Banned</div>}
                  {isDSRBanned && <div className="overlay">DSR Banned</div>}
                  <div className="card-body p-1">
                    <p className="card-text card-stage-text">{stage.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {stageBanTurn === gamertag && numBans < gameBanLimit && (
        <div className="stage-msg">
          <p>Select your stage ban(s).</p>
          <p>{turnPlayerBanLimit - selectedStages.length} ban(s) remaining</p>
        </div>
      )}
      {stageBanTurn !== gamertag && numBans < gameBanLimit && (
        <div className="stage-msg">
          <p>Opponent selecting stage ban(s)...</p>
        </div>
      )}
      {stageSelectTurn === gamertag &&
        numBans === gameBanLimit &&
        !stageSelected && (
          <div className="stage-msg">
            <p>Select a stage</p>
          </div>
        )}
      {stageSelectTurn === gamertag && stageSelected && (
        <div className="stage-msg">
          <p>Stage Selected!</p>
          <p>Waiting for opponent to confirm...</p>
        </div>
      )}
      {stageSelectTurn !== gamertag &&
        numBans === gameBanLimit &&
        !stageSelected && (
          <div className="stage-msg">
            <p>Opponent selecting stage...</p>
          </div>
        )}
      {stageSelectTurn !== gamertag && stageSelected && (
        <div className="stage-msg">
          <p>Stage Selected!</p>
          <p>Please confirm.</p>
          <button
            onClick={onStageConfirm}
            className="confirm-stage btn btn-outline-secondary"
          >
            Confirm
          </button>
        </div>
      )}
      {stageBanTurn === gamertag &&
        selectedStages.length === turnPlayerBanLimit &&
        turnPlayerBanLimit !== 0 && (
          <button
            onClick={handleConfirm}
            className="confirm-stage btn btn-outline-secondary"
            disabled={selectedStages.length < turnPlayerBanLimit}
          >
            Confirm
          </button>
        )}
      {gamertag === stageSelectTurn &&
        numBans === gameBanLimit &&
        selectedStages.length === 1 &&
        !stageSelected && (
          <button
            onClick={handleConfirm}
            className="confirm-stage btn btn-outline-secondary"
            disabled={selectedStages.length < 1}
          >
            Confirm
          </button>
        )}
    </>
  );
};

export default StageSelect;
