import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SearchBar.css";

const SearchBar = ({ collapseNavbar }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const [selectionSource, setSelectionSource] = useState("keyboard");

  useEffect(() => {
    const getSuggestions = async () => {
      if (query.length >= 2) {
        try {
          const res = await axios.get(
            `https://bracket-buddy.onrender.com/search/suggestions?query=${query}`
          );
          setSuggestions(res.data.suggestions);
        } catch (e) {
          console.error(e);
        }
      } else {
        setSuggestions([]);
      }
    };
    getSuggestions();
  }, [query]);

  useEffect(() => {
    if (searchRef.current) {
      const searchWidth = searchRef.current.offsetWidth;
      const suggestionContainer = document.getElementById(
        "suggestions-container"
      );
      if (suggestionContainer) {
        suggestionContainer.style.width = `${searchWidth}px`;
      }
    }
  }, [query]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setSuggestions([]);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    setQuery(e.target.value);
    setSelectedSuggestion(-1);
    setSelectionSource("mouse");
  };

  const navigateToPlayerProfile = (playerId) => {
    navigate(`/players/${playerId}`);
    collapseNavbar();
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" && selectedSuggestion > 0) {
      e.preventDefault();
      setSelectionSource("keyboard");
      if (selectionSource === "keyboard") {
        setSelectedSuggestion((prev) => prev - 1);
      }
    } else if (
      e.key === "ArrowDown" &&
      selectedSuggestion < suggestions.length - 1
    ) {
      e.preventDefault();
      setSelectionSource("keyboard");
      if (selectionSource === "keyboard") {
        setSelectedSuggestion((prev) => prev + 1);
      }
    } else if (e.key === "Enter" && selectedSuggestion >= 0) {
      e.preventDefault();
      if (selectedSuggestion >= 0) {
        navigateToPlayerProfile(suggestions[selectedSuggestion].id);
      } else {
        navigate(`/search?query=${query}`);
      }
      setSuggestions([]);
      setSelectedSuggestion(-1);
      collapseNavbar();
    }
  };

  const handleClick = (suggestion) => {
    navigate(`/players/${suggestion.id}`);
    setQuery("");
    setSuggestions([]);
    setSelectedSuggestion(-1);
    collapseNavbar();
  };

  const handleMouseEnter = (index) => {
    setSelectionSource("mouse");
    if (selectionSource === "mouse") {
      setSelectedSuggestion(index);
    }
  };

  const handleSearchClick = () => {
    collapseNavbar();
    navigate(`/search?query=${query}`);
    // setQuery('');
    // setSuggestions([]);
  };

  return (
    <div className="search-bar-container">
      <form
        className="input-group search-input-group"
        onSubmit={handleSearchClick}
      >
        <input
          id="query"
          name="query"
          className="form-control"
          type="text"
          placeholder="Search Player..."
          value={query}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          ref={searchRef}
          aria-label="Search"
          aria-describedby="Search"
        />
        <button
          className="btn btn-outline-secondary search-button"
          type="button"
          id="button-addon2"
          onClick={handleSearchClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
        </button>
        {suggestions.length > 0 && (
          <ul className="list-group mt-2 suggestions">
            {suggestions.map((suggestion, index) => (
              <li
                key={suggestion.id}
                className={
                  selectedSuggestion === index
                    ? "list-group-item player-search-list highlight-suggestion active"
                    : "list-group-item player-search-list highlight-suggestion"
                }
                style={{ padding: 0 }}
                onClick={() => handleClick(suggestion)}
                onMouseEnter={() => handleMouseEnter(index)}
              >
                <div className="player-searchbar-card">
                  <div className="img-container">
                    {suggestion.image ? (
                      <img
                        src={suggestion.image}
                        alt="Player"
                        className="rounded-img"
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/BBL.png"}
                        alt="BracketBuddy"
                        className="navbar-img"
                      />
                    )}
                  </div>
                  <div className="player-info">
                    <p className="player-search-card-tag">
                      {suggestion.gamertag}{" "}
                      <span
                        className={`fi fi-${suggestion.country.toLowerCase()}`}
                      />
                    </p>
                    <p className="player-name">
                      {suggestion.name ? `(${suggestion.name})` : ""}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </form>
    </div>
  );
};

export default SearchBar;
