import React, { useState } from "react";
import "./CharacterCard.css";

const CharacterCard = ({ each, index }) => {
  const [flip, setFlip] = useState(false);

  const handleClick = () => {
    setFlip(!flip);
  };

  return (
    <>
      <div className={`card char-card`} key={each.fighter_id}>
        <div className="card-inner">
          <div className="card-front">
            <img
              src={each["icon"]}
              className="char-card-img-top"
              alt={each.name}
              style={{
                backgroundColor:
                  index === 0 ? "#faedcd" : index === 1 ? "#dee2e6" : "#ddb892",
              }}
            />
            <div className="card-body">
              <div className="card-title text-center">
                <div>{each.name}</div>
                <div
                  className="character-record"
                  style={{
                    color:
                      each.wins > each.usage - each.wins
                        ? "green"
                        : each.wins < each.usage - each.wins
                        ? "red"
                        : "#0067da",
                  }}
                >
                  ({each.wins}-{each.usage - each.wins})
                </div>
              </div>
            </div>
            <span className="badge bg-primary position-absolute top-0 end-0">
              <span style={{ fontSize: "0.8rem" }}>x</span>
              {each.usage}
            </span>
          </div>
          {/* <div className="card-back" onClick={handleClick}>
            <p className="card-back-content">testing</p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CharacterCard;
